import React, { useContext, useEffect, useState } from "react";

interface IPageMetaContext {
    show?: boolean;
    title?: string;
    subtitle?: string;
    description?: string;
    bar?: string;
    setMeta: (meta: any) => void;
}

export const PageMetaContext = React.createContext<IPageMetaContext>({
    show: false,
    title: "",
    subtitle: "",
    description: "",
    bar: "",
    setMeta: (meta: any) => {},
});

const PageMetaProvider = ({ children }: any) => {
    const [meta, setMeta] = useState<any>({
        title: "",
        subtitle: "",
        description: "",
        show: false,
    })
    return <PageMetaContext.Provider value={{ ...meta, setMeta }}>
        {children}
    </PageMetaContext.Provider>
}
interface IPageMeta {
    title?: string;
    subtitle?: string;
    description?: string;
    show?: boolean;
    bar?: string;
}
export const usePageMeta = (pagemeta?: IPageMeta) => {
    const meta = useContext(PageMetaContext);
    meta.description = pagemeta?.description;
    meta.subtitle = pagemeta?.subtitle;
    meta.title = pagemeta?.title;
    meta.show = pagemeta?.show;
    meta.bar = pagemeta?.bar;
    useEffect(() => {
        meta.setMeta(meta);
    }, []);
    const show = () => meta.setMeta({...meta, show: true})
    const hide = () => meta.setMeta({...meta, show: false})
    return {show, hide, setMeta: meta.setMeta}
}

export default PageMetaProvider;
