import { IBusinessUnit, IConvenio } from "../interfaces";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class BusinessUnitsService extends AbstractService<IBusinessUnit> {
    constructor(){
        super("/unidad_de_gestion")
    }
    
    getAllBU(filters?: Filter<IBusinessUnit>): Promise<[Error, PaginatedResult<IBusinessUnit>]> {
        return this.getAll(filters, "/");
    }
}

export default new BusinessUnitsService();