import React from "react";
import { UseFormProps, useForm, FormProvider } from "react-hook-form";

interface Props {
    children: React.ReactNode;
    onSubmit?: (data: any) => void;
    onSubmitError?: (data: any) => void;
    onChange?: () => {}
}

const useFormProvider = (options?: UseFormProps) => {
    const methods = useForm(options);

    const Form = React.useMemo(() => ({ children, onSubmit, onSubmitError, id }: any) =>
        <FormProvider {...methods}>
        <form id={id} onSubmit={onSubmit && methods.handleSubmit(onSubmit, onSubmitError)}>
            {children}
        </form>
    </FormProvider>, []);
    return {
        Form,
        methods
    }
}
export default useFormProvider;