import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormProvider from "../../hooks/useFormProvider";
import { Manager, ManagerType } from "../../interfaces/manager.interface";
import schema from "../../schemas/manager.schema";
import { Button, Input, Select } from "../common";
import managerTypeService from "../../services/managerType.service";
import { useParams } from "react-router-dom";

interface Props {
    onSubmit?: (data: any) => void;
    defaultValues?: Partial<Manager>;
    disabled?: boolean;
}
const ManagersForm = ({ onSubmit, defaultValues, disabled }: Props) => {
    const {id} = useParams();
    const { Form, methods } = useFormProvider({ resolver: yupResolver(schema) });
    const [managerTypes, setManagerTypes] = useState<ManagerType[]>([]);

    const getManagerTypes = async (id: string) => {
        const [error, data] = await managerTypeService.getAll();
        if(error) return;
        setManagerTypes(data.results);
    }

    useEffect(() => {
        if(id) getManagerTypes(id);
    }, [id])

    useEffect(() => {
        methods.reset({
            tipo: 1,
            ...defaultValues,
        })
    }, [])

    return <Form onSubmit={onSubmit}>
        <div className="px-2">
            <div className="row mb-2">
                <div className="col-md-6">
                    <Input name="nombre" type="text" disabled={disabled} label="Nombre según DNI" />
                </div>
                <div className="col-md-6">
                    <Input name="apellido" type="text" disabled={disabled} label="Apellido según DNI" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-md-6">
                    <Select name="tipo" disabled={disabled} label="Tipo de representante" options={managerTypes} getLabel={(opt: any) => opt.tipo} getValue={(opt: any) => opt.id }/>
                </div>
                <div className="col-md-6">
                    <Input name="cuil" type="text" disabled={disabled} label="CUIL" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-md-6">
                    <Input name="fecha_de_inicio" type="date" disabled={disabled} label="Fecha de Inicio" />
                </div>
                <div className="col-md-6">
                    <Input name="fecha_de_fin" type="date" disabled={disabled} label="Fecha de Fin" />
                </div>
            </div>

            <div className="row mb-2">
                <div className="col-md-6">
                    <Input name="telefono" type="text" disabled={disabled} label="Teléfono" />
                </div>
                <div className="col-md-6">
                    <Input name="email" type="text" disabled={disabled} label="Email" />
                </div>
            </div>
            {!disabled && <div className="row mb-2">
                <div className="col-6">
                    <Button variant="primary">Guardar</Button>
                </div>
            </div>}
        </div>
    </Form>
}

export default ManagersForm;