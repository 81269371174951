import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const LogoutPage = () => {
    const { logout } = useAuth();
    useEffect(() => {
        logout();
    }, []);
    return <Navigate to="/login" replace />;
}
export default LogoutPage;