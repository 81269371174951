import { useEffect, useState } from "react"
import { IProvincia } from "../interfaces"
import provinciasService from "../services/provincias.service";

const useProvinces = () => {
    const [data, setData] = useState<IProvincia[]>();
    const getProvincias = async () => {
        const [error, data] = await provinciasService.getAll();
        if(error) return;
        setData([{id: 0, nombre: "Seleccione", codigo_UTA: ""}, ...data.results]);
    }
    useEffect(() => {
        console.log("getting again")
        getProvincias();
    }, []);
    return data;
}
export default useProvinces;