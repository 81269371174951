import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useFormProvider from "../../../../hooks/useFormProvider";
import useRequestOptions from "../../../../hooks/useRequestOptions";
import schema from "../../../../schemas/requestCreate.schema";
import { Button, Input, Select, TextArea } from "../../../common";

interface Props {
    values?: any;
    onChange: (value: any) => void;
    nextStep: () => void;
}

const StartNewRequest = ({ values, onChange, nextStep }: Props) => {

    const { Form, methods } = useFormProvider({
        resolver: yupResolver(schema), mode: "all", defaultValues: {
            tipo: 0,
            fecha: new Intl.DateTimeFormat('sv').format(new Date()),
            ...values
        }
    });
    const navigate = useNavigate();
    const requestOptions = useRequestOptions();

    const onChangeInternal = (form: any) => {
        const data = {...form};
        data.fecha =  new Intl.DateTimeFormat('sv').format(data.fecha);
        onChange(data);
        nextStep();
    }

    useEffect(() => {
        methods.reset(values);
    }, [values])

    return <div className="row">
        <h3 className="mb-3">Datos de la Solicitud</h3>
        <Typography className="mb-4">Complete los datos principales de la solicitud que desea efectuar</Typography>
        <Form id="alta" onSubmit={(value: any) => {onChangeInternal(value)}}>
            <Select name="tipo" label="Tipo" options={requestOptions as any} />
            <Input type="date" name="fecha" label="Fecha" />
            <TextArea name="descripcion" label="Descripción" />
            <Input type="text" name="estado" label="Estado" defaultValue="Iniciada" disabled />
            <Grid container xs={12} mt={3} justifyContent="center" gap={3}>
                <Button type="button" variant="link" className="border border-secondary" size="lg" onClick={() => navigate(-1)}>Anterior</Button>
                <Button type="submit" variant="success" size="lg" disabled={!methods.formState.isValid} >Siguiente</Button>
            </Grid>
        </Form>
    </div>
}

export default StartNewRequest;