import { useLocation, useNavigate } from "react-router-dom"
import { Button } from "../components/common";

export const RecoveryPasswordSuccesfullPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="col-lg-4 col-md-10 mt-5 mx-3">
                <div className="row">
                    <h4>Revisá tu correo electrónico</h4>
                    <p>Te va a llegar un correo electrónico a {location.state.email} con instrucciones para poder recuperar tu contraseña.</p>
                    <br />
                </div>
                <Button variant="primary" onClick={() => navigate('/')} >VOLVER AL INICIO</Button>
            </div>
        </div>
  )
}
