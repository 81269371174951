import { useEffect, useMemo, useState } from "react"
import { Button } from "../components/common"
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable"
import { IColumn } from "../components/custom/Table/interfaces/Column"
import useFilters from "../hooks/useFilters"
import { PaginatedResult } from "../interfaces/paginated.result.interface"
import { Category } from "../interfaces/category.interface"
import amountPerCategoryService from '../services/amoutPerCategory.service'
import { Link, useNavigate, useParams } from "react-router-dom"
import categoryNamesService from "../services/categoryNames.service"
import { CategoryName } from "../interfaces/categoryName.interface"
import { formatNumber } from "../utils/text.utils"

export const AmountPerCategoryPage = () => {

    const { filters, onPageChange } = useFilters<Category>({
        pagination: { limit: 5  }
    })
    const [nameMap, setNameMap] = useState<Record<string, CategoryName>>({});
    const { id } = useParams();

    const [category, setCategory] = useState<PaginatedResult<Category>>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();

    const getCategoryNames =  async () => {
        const [error, data] = await categoryNamesService.getAll();
        if (!error) {
            setNameMap(data.results.reduce((acc, rubro) => ({
                ...acc,
                [rubro.id]: rubro
            }), {}));
        }
    }

    const columns: IColumn<Category>[] = useMemo<IColumn<Category>[]>(() => {
        return [
            {
                displayName: "Rubro",
                accesor: "rubro",
                displayFn: ({value}) => <span>{nameMap?.[value]?.nombre}</span> 
            },
            {
                displayName: "Monto",
                accesor: "monto",
                displayFn: ({value}) => <span>{value !== undefined ? `$ ${formatNumber(value.toString())}` : ""}</span>
            },
            {
                displayName: "Detalle",
                displayFn: ({ row }) => <Link to={`${row.id}`}><Button variant="primary" size="sm">Ver más</Button></Link>
            }
        ]
    }, [nameMap]);

    const getCategory = async (id: string) => {
        setIsLoading(true);
        const [error, data] = await amountPerCategoryService.getAmounts(id, filters);
        setIsLoading(false);
        if (!error) setCategory(data);
    }

    useEffect(() => {
        if(id){
            getCategory(id);
            getCategoryNames();
        }
    }, [id, filters])

    return (
        <div className="container">
            <h1 className="mt-4">Monto por Rubro</h1>
            <div className="row w-100">
                <div className="d-flex align-items-center">
                    <h4 className="col-sm-9 col-xs-12 m-2">Grilla de monto</h4>
                    <Button variant="success" size="lg" className="col-md-3" onClick={() => navigate("alta")}>Cargar montos</Button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-4 mt-3">
                    <PaginatedTable data={category?.results} columns={columns} onPageChange={onPageChange} meta={{
                        ...category?.meta,
                        ...filters.pagination
                    }}
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>
    )
}