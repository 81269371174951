import { useState } from 'react';
import { Link, Navigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import schema from "../schemas/auth.schema";
import { IAuth } from "../interfaces";
import Form from "../components/common/Form";
import Input from "../components/common/Input";
import useAuth from "../hooks/useAuth";
import { Alert, Button } from '../components/common';

const LoginPage = () => {
    const { login, isAuth } = useAuth();
    const [{ cuit, password }, setErrors] = useState({
        cuit: { message: '' },
        password: { message: '' }
    });

    const onSubmit = async (data: IAuth) => {
        const success = await login(data.cuit, data.password);
        console.log(success);
        if (!success) {
            setErrors(() => ({ cuit: { message: '' }, password: { message: "Usuario y/o contraseña incorrectos" } }))
        }
    };

    const onSubmitError = (errors: any) => setErrors(errors);

    if (isAuth) return <Navigate to="/unidadesgestion" replace />;
    return (
        <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="col-lg-4 col-md-10 mt-5 mx-3">
                <div className="row">
                    <h4 className='mb-4'>Ingresá a la plataforma Unidades de Gestión</h4>
                    <div className="col-md-10">
                        {(cuit?.message || password?.message) &&
                            <Alert type='danger' label='Se han producido los siguientes errores: '>
                                {cuit?.message && <div>{cuit?.message}</div>}
                                {password?.message && <div>{password?.message}</div>}
                            </Alert>
                        }

                        <Form
                            onSubmit={onSubmit}
                            onSubmitError={onSubmitError}
                            options={{ resolver: yupResolver(schema) }}>
                            <div className='mb-3'>
                            <Input name="cuit" label="CUIT" hideErrors />

                            </div>
                            <Input name="password" type="password" label="Contraseña" hideErrors />

                            <p className='help-block'>Ingresá tu contraseña</p>
                            <Button type="submit" variant="success">Ingresar</Button>
                        </Form>
                        <hr />
                        <Link to={"/recuperar-contrasena"} replace>Reestablecer Contraseña</Link>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default LoginPage;