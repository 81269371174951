import { IConvenio } from "../interfaces";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class ConveniosService extends AbstractService<IConvenio> {
    constructor(){
        super("/convenio")
    }
    getConvenios(uid: string, filters?: Filter<IConvenio>): Promise<[Error, PaginatedResult<IConvenio>]> {
        return this.getAll(filters, `/ug/${uid}/`) as any;
    }
}

export default new ConveniosService();