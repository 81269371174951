import { mergeClasses } from "../../../utils/merge.classnames";
import ButtonBase from "./ButtonBase";

interface Props {
    variant: "primary" | "secondary" | "success" | "danger" | "link" | "light";
    className?: string;
    children?: any;
    onClick?: () => void;
    [x: string]: any;
}

const mapVariants = {
    primary: "btn-primary",
    secondary: "btn-secondary",
    success: "btn-success",
    danger: "btn-danger",
    link: "btn-link",
    light: "btn-light"
};

const Button = ({ variant = 'primary', className, children, onClick, ...restProps }: Props) => {
    return (
      <ButtonBase
        {...restProps}
        onClick={onClick}
        className={mergeClasses(
            mapVariants[variant],
            className
        )}
      >{children}</ButtonBase>
    );
  }

export default Button;