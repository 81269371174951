import { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button } from "../components/common"
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable"
import { IColumn } from "../components/custom/Table/interfaces/Column"
import useFilters from "../hooks/useFilters"
import { ConveniosRequest } from "../interfaces/conveniosRequest.interface"
import { PaginatedResult } from "../interfaces/paginated.result.interface"
import conveniosRequestService from "../services/conveniosRequest.service"

export const ConveniosRequestPage = () => {

    const { filters, onPageChange } = useFilters<ConveniosRequest>({
        pagination: { limit: 5 }
    });
    const [request, setRequest] = useState<PaginatedResult<ConveniosRequest>>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();
    const { id } = useParams();

    const columns: IColumn<ConveniosRequest>[] = useMemo<IColumn<ConveniosRequest>[]>(() => {
        return [
            {
                displayName: "Tipo de solicitud",
                accesor: "tipo"
            },
            {
                displayName: "Estado",
                accesor: "estado"
            },
            {
                displayName: "Detalle",
                displayFn: ({ row }) => <Link to={`${row.id}`}><Button variant="primary" size="sm">Ver más</Button></Link>
            }
        ]
    }, []);

    const getConveniosRequest = async () => {
        if (id) {
            setIsLoading(true);
            const [error, data] = await conveniosRequestService.getAllRequestById(id, filters);
            setIsLoading(false);
            if (!error) setRequest(data);
        }
    }

    useEffect(() => {
        getConveniosRequest();
    }, [filters])


    return (
        <div className="container">
            <div className="row align-items-center justify-content-center my-3">
                <div className="col-md-8">
                    <h1>Solicitudes</h1>
                </div>
                <div className="col-md-4">
                    <Button variant="success" size="lg" onClick={() => navigate("alta")}>Cargar una nueva solicitud</Button>
                </div>
            </div>
            <div className="row mt-4">
                <h3>Grilla actual</h3>
            </div>
            <div className="row">
                <PaginatedTable data={request?.results} columns={columns} onPageChange={onPageChange} meta={{
                    ...request?.meta,
                    ...filters.pagination
                }}
                    isLoading={isLoading} />
            </div>
        </div>
    )
}