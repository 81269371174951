import { Documentation } from "../interfaces/documentation.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class DocumentationService extends AbstractService<Documentation> {
    constructor(){
        super("/documentacion_ug")
    }

    getDocumentation(uid: string, filters?: Filter<Documentation>): Promise<[Error, PaginatedResult<Documentation>]> {
        return this.getAll(filters, `/convenio/${uid}/`) as any;
    }
    saveDocumentation(uid: string, body: Documentation) : Promise<[Error, Documentation]>{
        return this.client.postForm(`/convenio/${uid}/add_documentacion/`, body);
    }

}

export default new DocumentationService();