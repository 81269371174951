import { PaginationMeta } from "../../../interfaces/paginated.result.interface";
import { Pagination } from "../../common";
import { IColumn } from "../Table/interfaces/Column";
import Table from "../Table/Table";

interface Props<T> {
    data?: T[];
    columns: IColumn<T>[];
    meta?: PaginationMeta;
    onPageChange?: (page: number) => void;
    isLoading?:boolean;
}

const PaginatedTable = <T extends unknown>({ data, columns, meta, onPageChange, isLoading }: Props<T>) => {
    return <div className="d-flex flex-column align-items-center justify-content-center gap-3 w-100">
        <div className="text-left w-100">{meta?.count} resultados</div>
        <Table data={data} columns={columns} isLoading={isLoading} />
        {meta && <div className="mb-3">
            <Pagination meta={meta} onPageChange={onPageChange} />
        </div>}
    </div>
}

export default PaginatedTable;