import React from 'react';
import { requestTypes } from '../constants/request.types';

const useRequestOptions = () => {

    const requestOptions = React.useMemo(() => {
        const options = ["Seleccione un área..."]
        options.push(...requestTypes);
        return options.map((name, index) => ({label: name, value: index}));
    }, []);

    return requestOptions;
}
export default useRequestOptions;