import { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom";
import { Button } from "../components/common";
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable"
import { IColumn } from "../components/custom/Table/interfaces/Column";
import useFilters from "../hooks/useFilters";
import { Intervention } from "../interfaces/intervention.interface"
import { PaginatedResult } from "../interfaces/paginated.result.interface";

export const InterventionPage = () => {

    const { filters, onPageChange } = useFilters<Intervention>({
        pagination: { limit: 5 }
    })

    const [intervention, setIntervention] = useState<PaginatedResult<Intervention>>();
    const [isLoading, setIsLoading] = useState<boolean>();

    const mock: PaginatedResult<Intervention> = {
        meta: { limit: 5, count: 5, page: 1 },
        results: [
            {
                fecha: 12,
                id: "1",
                nro_expediente: "00000000000",
                tipo: "tipo 1"
            },
            {
                fecha: 12,
                id: "2",
                nro_expediente: "00000000000",
                tipo: "tipo 1"
            },
            {
                fecha: 12,
                id: "3",
                nro_expediente: "00000000000",
                tipo: "tipo 1"
            },
            {
                fecha: 12,
                id: "4",
                nro_expediente: "00000000000",
                tipo: "tipo 1"
            },
            {
                fecha: 12,
                id: "5",
                nro_expediente: "00000000000",
                tipo: "tipo 1"
            },
        ]
    };

    const columns: IColumn<Intervention>[] = useMemo<IColumn<Intervention>[]>(() => {
        return [
            {
                displayName: "Tipo de intervención",
                accesor: "tipo"
            },
            {
                displayName: "Fecha",
                accesor: "fecha"
            },
            {
                displayName: "Nro de expediente",
                accesor: "nro_expediente"
            },
            {
                displayName: "Detalle",
                displayFn: ({ row }) => <Link to={`${row.id}`}><Button variant="primary" size="sm">Ver más</Button></Link>
            }
        ]
    }, []);

    const getInterventions = async () => {
        setIsLoading(true);
        setIntervention(mock);
        setIsLoading(false);
    }

    useEffect(() => {
        getInterventions();
    })

    return (
        <div className="container">
            <div className="row">
                <h1 className="my-3">Intervenciones</h1>
            </div>
            <div className="col-md-12 ms-4">
                <h3>Grilla de intervenciones</h3>
                <div className="row">
                    <PaginatedTable data={intervention?.results} columns={columns} onPageChange={onPageChange} meta={{
                        ...intervention?.meta,
                        ...filters.pagination
                    }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    )
}