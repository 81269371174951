import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import useFormProvider from "../../hooks/useFormProvider";
import { Button, Input, Select } from "../common";
import { Category } from "../../interfaces";
import { CategoryName } from "../../interfaces/categoryName.interface";
import categoryNamesService from "../../services/categoryNames.service";
import schema from "../../schemas/category.schema";

interface Props {
    onSubmit?: (data: any) => void;
    defaultValues?: Partial<Category>;
    disabled?: boolean;
}
const AmountPerCategoryForm = ({ onSubmit, defaultValues, disabled }: Props) => {
    const { Form, methods } = useFormProvider({ resolver: yupResolver(schema) });

    const [options, setOptions] = useState<CategoryName[]>()

    const getCategoryNames = async () => {
        const [error, data] = await categoryNamesService.getAll();
        if (error) return;
        setOptions(data.results)
        if(!defaultValues?.rubro) {
            methods.setValue("rubro", data.results[0].id);
        }
    }

    useEffect(() => {
        getCategoryNames();
    }, [])

    useEffect(() => {
        methods.reset({
            ...defaultValues,
        })
    }, [])

    return <Form onSubmit={onSubmit}>
                <div className="col-md-12">
                    <Select name="rubro" options={options} getLabel={(opt: any) => opt.nombre} getValue={(opt: any) => opt.id} label="Rubro" disabled={disabled} />
                    <Input name="monto" label="Monto" disabled={disabled} />
                </div>
            {!disabled && <div className="row mb-2">
                <div className="col-6">
                    <Button variant="primary" type="submit">Guardar</Button>
                </div>
            </div>}
    </Form>
}

export default AmountPerCategoryForm;