import { Outlet } from "react-router-dom";
import { Footer, Header } from "../common";
import './styles.scss';

const DefaultLayout = () => {
    return <div className="w-100 h-100 overflow-y-auto">
        <Header />
        <div className="default-layout-container">
            <Outlet />
        </div>
        <Footer />
    </div>
}

export default DefaultLayout;