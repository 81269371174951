import { useNavigate } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import './styles.scss'
import Button from '../Button/Button';
const Header = () => {
    const navigate = useNavigate();
    const { isAuth, user } = useAuth();
    const formatCuit = (str: string) => str.substring(0, 2) + "-" + str.substring(2, 10) + "-" + str.substring(10);
    return (
        <header className="header">
            <div className='container py-2 d-flex justify-content-between d-flex justify-content-between align-items-center h-100'>
                <a className="navbar-brand" href="https://www.argentina.gob.ar/" aria-label="Argentina.gob.ar Presidencia de la Nación">
                    <img alt="Argentina.gob.ar" src="/images/argentinalogo.svg" height="50" width="236" />
                </a>
                {!isAuth ? <Button variant="light" className='text-primary py-2' size="base" onClick={() => navigate("login")}>Ingresar</Button> :
                    <DropdownButton variant="light" size='lg' title="Mi cuenta">                        
                        <Dropdown.Header className="text-black fw-bold text-center">{user?.first_name} {user?.last_name}</Dropdown.Header>
                        <Dropdown.ItemText className="text-center">{formatCuit(user?.cuit || "")}</Dropdown.ItemText>
                        <Dropdown.Divider />
                        <Dropdown.Item className="text-primary" href="/logout">Cerrar sesión</Dropdown.Item>
                    </DropdownButton>
                }
            </div>
        </header>
    )
}

export default Header;