import { NavLink } from "react-router-dom"

interface Props {
    bgcolor: string;
    icon: string;
    label?: string;
    to: string;
}

const NavIcon = ({ bgcolor, icon, label, to }: Props) => {
    return <>
        <NavLink to={to} className={`text-decoration-none text-black`}>
            <div className="d-flex flex-column align-items-center">
                <i className={`nav-icon ${icon}`} style={{ backgroundColor: bgcolor }} />
                <span>{label}</span>
            </div>
        </NavLink>
    </>
}

export default NavIcon;