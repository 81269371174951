import { Category } from "../interfaces/category.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class AmountPerCategoryService extends AbstractService<Category> {
    constructor(){
        super("/monto_por_rubro")
    }
    getAmounts(uid: string, filters?: Filter<Category>): Promise<[Error, PaginatedResult<Category>]> {
        return this.getAll(filters, `/convenio/${uid}/`) as any;
    }
    saveAmount(uid: string, cat: Category):  Promise<[Error, Category]> {
        return this.client.post(`/convenio/${uid}/`, cat);
    }
}

export default new AmountPerCategoryService();