import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form"

interface Props {
    label?: string;
    name: string;
    type?: string;
    required? :boolean;
    disabled?: boolean;
    defaultValue?: string;
    onChange?: (e: any) => void;
}

const TextArea = ({label, name, required, onChange, disabled, defaultValue}: Props) => {
    const { register, formState: {errors = {}} } = useFormContext();
    const hasErrors = !!errors[name]?.message;
    const props = register(name);
    
    const onChangeInternal = (e: ChangeEvent<HTMLTextAreaElement>) =>{
        props.onChange(e);
        onChange?.(e);
    }

    return (
        <div className={`form-group item-form ${hasErrors ? 'has-error' : ''}`}>
            <label className="control-label" htmlFor={name}>{label}</label>
            <textarea
                {...props}
                onChange={onChangeInternal}
                className="form-control"
                id={name}
                name={name}
                required={required}
                disabled={disabled}
                defaultValue={defaultValue}
                aria-required={required}
                />
            <p className="help-block error text-danger" role="alert">{errors?.[name]?.message?.toString()}</p>
        </div>
    )
}

export default TextArea;