import { Outlet } from "react-router-dom";
import PageMetaProvider from "../../context/pagemeta.context";
import { BreadCrums } from "../common";
import PageMeta from "../custom/PageMeta/PageMeta";

const UGLayout = () => {
    return <div>
        <PageMetaProvider>
            <div className="jumbotron">
                <BreadCrums />
                <PageMeta />
                <div className="overlay"></div>
            </div>
            <Outlet />
        </PageMetaProvider>
    </div>
}

export default UGLayout;