import { useState } from "react"
import { ILocalidad } from "../interfaces"
import provinciasService from "../services/provincias.service";

const useCities = () => {
    const [data, setData] = useState<Partial<ILocalidad>[]>();
    const getCities = async (provinceId: string | number, departamentoId: string | number, gobiernoLocalId: string | number) => {
        const [error, data] = await provinciasService.getLocalidades(provinceId, departamentoId, gobiernoLocalId);
        if(error) return;
        setData([{id: 0, nombre: "Seleccione"}, ...data.results]);
    }

    return {getCities, cities: data};
}
export default useCities;