import React from 'react';
import { Grid } from "@mui/material";
import useFormProvider from "../../../../hooks/useFormProvider";
import useRequestOptions from "../../../../hooks/useRequestOptions";
import { Button, Input, Select, TextArea } from "../../../common";
import { IColumn } from "../../Table/interfaces/Column";
import Table from '../../Table/Table';

interface Props {
    type: number;
    values: any;
    prev: () => void;
    onChange: (value: any) => void;
    isLoading: boolean;
}

const Step3Generic = ({ values, prev, onChange, isLoading }: Props) => {

    const columns: IColumn<File>[] = React.useMemo<IColumn<File>[]>(() => {
        return [
            {
                displayName: "Documento",
                accesor: "name"
            },
            {
                displayName: "Adjunto",
                displayFn: ({ row }) => <a target="_blank" href={URL.createObjectURL(row)} rel="noreferrer"><Button variant="primary" size="sm"><i className="fa fa-download me-2"></i>Descargar</Button></a>
            }
        ]
    }, []);
    
    const { Form, methods } = useFormProvider({
        defaultValues: {
            ...values
        }
    });
    const requestOptions = useRequestOptions();

    return <div className="d-flex flex-column align-items-center justify-content-center">
        <h3 className="pb-3">Enviar solicitud</h3>
        <p className="pb-4">Corrobore que <span className="fw-bold">todos los datos</span> estén correctos antes de enviar la solicitud</p>
        <div className="row w-100">
            <Form id="alta" onSubmit={onChange}>
                <Select name="tipo" label="Tipo" options={requestOptions as any} disabled />
                <Input type="date" name="fecha" label="Fecha" disabled />
                <TextArea name="descripcion" label="Descripción" disabled />
                <Input type="text" name="estado" label="Estado" defaultValue="Iniciada" disabled />
                <div className="row mt-5">
                    <h3 className="mb-3">Documentación Adjunta</h3>
                    <Table data={methods.getValues("adjunto")} columns={columns} />
                </div>
                <Grid container xs={12} mt={3} justifyContent="center" gap={3}>
                    <Button type="button" variant="link" className="border border-secondary" size="lg" onClick={prev} disabled={isLoading}>Anterior</Button>
                    <Button type="submit" variant="success" size="lg" disabled={isLoading}>Enviar solicitud</Button>
                </Grid>
            </Form>
        </div>
    </div>
}

export default Step3Generic;