const routes = {
    "unidadesgestion": {
        displayName: "Unidades de gestion",
    },
    "convenios": {
        displayName: "Convenios",
    },
    "responsables": {
        displayName: "Responsables"
    },
    "actividades": {
        displayName: "Actividades"
    },
    "montoporrubro": {
        displayName: "Monto Por Rubro"
    },
    "intervenciones": {
        displayName: "Intervenciones"
    },
    "documentacion": {
        displayName: "Documentacion"
    },
    "solicitudes": {
        displayName: "Solicitudes"
    },
    
} as any;

export default routes;