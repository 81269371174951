import { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../components/common";
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import { usePageMeta } from "../context/pagemeta.context";
import useFilters from "../hooks/useFilters";
import { IBusinessUnit, IConvenio } from "../interfaces";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import { Filter } from "../services/abstract.service";
import businessunitsService from "../services/businessunits.service";
import conveniosService from "../services/conveniosService";

const UGDetail = () => {

    const { setMeta } = usePageMeta({ show: true, title: "Convenios", bar: "COOPERATIVA DE TRABAJO GENEOS LIMITADA" });
    const [convenios, setConvenios] = useState<PaginatedResult<IConvenio>>();
    const { ugId } = useParams();

    const [isLoading, setIsLoading] = useState<boolean>();

    const { filters, onPageChange } = useFilters<IConvenio>({
        pagination: {limit: 15}
    });

    const columns = useMemo<IColumn<IConvenio>[]>(() => {
        return [
            {
                displayName: 'N° de Expediente',
                accesor: "numero_de_expediente"
            },
            {
                displayName: 'Estado',
                accesor: "estado"
            },
            {
                displayName: 'Vencimiento',
                accesor: "fecha_de_vencimiento"
            },
            {
                displayName: 'Convenio',
                displayFn: ({ row }) => <Link to={`${row.id}`}><Button variant="primary" size="sm">Acceder</Button></Link>

            }
        ]
    }, [])

    useEffect(() => {
        if (ugId) {
            getBusinessUnitById(ugId);
            getConvenios(ugId, filters);
        }
    }, [ugId]);

    useEffect(() => {
        if (ugId) getConvenios(ugId, filters)
    }, [filters])

    const getBusinessUnitById = async (id: string) => {
        const [error, data] = await businessunitsService.getOne(id);
        if (error) return;
        const UG: IBusinessUnit = (data as any).results?.[0];
        setMeta({ subtitle: 'BIENVENIDO A LA PLATAFORMA', title: 'Convenios', show: true, description: 'Estos son los convenios', bar: UG?.razon_social });
    }
    
    const getConvenios = async (id: string, filters?: Filter<IConvenio>) => {
        setIsLoading(true);
        const [error, data] = await conveniosService.getConvenios(id, filters);
        setIsLoading(false);
        if (error) return;
        setConvenios(data);
    }

    return <div className="d-flex justify-content-center align-items-center w-100">
        <div className="col-10 mt-4">
        <PaginatedTable data={convenios?.results} columns={columns} meta={{
            ...convenios?.meta,
            ...filters.pagination,
        }} 
        onPageChange={onPageChange}
        isLoading={isLoading} />
        </div>
    </div>
}

export default UGDetail;