import { useState } from "react"
import { IDepartamento } from "../interfaces"
import provinciasService from "../services/provincias.service";

const useDepartment = () => {
    const [data, setData] = useState<IDepartamento[]>();
    const getDepartaments = async (provinceId: number) => {
        const [error, data] = await provinciasService.getDepartamentos(provinceId);
        if(error) return;
        setData([{id: 0, nombre: "Seleccione", codigo_UTA: ""}, ...data.results]);
    }

    return {getDepartaments, departments: data};
}
export default useDepartment;