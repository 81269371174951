import { useNavigate, useParams } from "react-router-dom";
import AmountPerCategoryForm from "../components/form/AmountPerCategoryForm";
import { Category } from "../interfaces";
import amoutPerCategoryService from "../services/amoutPerCategory.service";

export interface Novedades {
    id?: number,
    tipo: string;
    fecha: Date;
    usuario: string;
}

const CategoryNew = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const onSubmit = async (category: Category) => {
        if(!id) return;
        const [error] = await amoutPerCategoryService.saveAmount(id, category)
        if(error) return;
        navigate(-1)
    }

    return (
        <div className="col-12 col-md-10 col-lg-8 col-xl-5 m-auto">
        <div className="row w-75 my-3">
            <h1 className="col-md-12">Cargar monto</h1>
        </div>
       <AmountPerCategoryForm disabled={false}  onSubmit={onSubmit}/>
    </div>
    )
}

export default CategoryNew;