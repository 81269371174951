import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Select, TextArea } from "../components/common";
import { ConveniosRequest } from "../interfaces/conveniosRequest.interface";
import conveniosRequestService from "../services/conveniosRequest.service";
import { Stepper, Step, StepLabel, Typography, Paper, StepConnector } from '@mui/material';
import useFormProvider from "../hooks/useFormProvider";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "../schemas/requestCreate.schema";
import Step2Generic from "../components/custom/RequestStepper/Steps/Step2Generic";
import Step3Generic from "../components/custom/RequestStepper/Steps/Step3Generic";
import StartNewRequest from "../components/custom/RequestStepper/Steps/StartNewRequest";
import Step2Manager from "../components/custom/RequestStepper/Steps/Step2Manager";
import { requestTypeMap } from "../constants/requestTypeMap";

const RequestNew = () => {

    const { id } = useParams();
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { methods } = useFormProvider({
        resolver: yupResolver(schema), mode: "onBlur", defaultValues: {
            tipo: 0,
            fecha: new Intl.DateTimeFormat('sv').format(new Date()),
            adjunto: [],
        }
    });

    const requestType = methods.watch("tipo");


    const steps = [
        { label: 'Ingrese los datos', description: "" },
        { label: 'Solicitud', description: "" },
        { label: 'Confirmar', description: "" }
    ];

    const onChange = (values: any) => {
        const formValues = methods.getValues();
        methods.reset({
            ...formValues,
            ...values,
        });
    }

    useEffect(() => {
        const formValues = methods.getValues();
        methods.reset({
            ...formValues,
            adjunto: []
        });
    }, [requestType])


    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <StartNewRequest
                    onChange={onChange}
                    values={methods.getValues()}
                    nextStep={nextStep}
                />
            case 1:
                if(requestType === 5){
                    return <Step2Manager
                    type={requestType}
                    values={methods.getValues()}
                    next={nextStep}
                    prev={prevStep}
                    onChange={onChange}
                />
                }
                return <Step2Generic
                    type={requestType}
                    values={methods.getValues()}
                    next={nextStep}
                    prev={prevStep}
                    onChange={onChange}
                />

            case 2: return <Step3Generic
                type={requestType}
                values={methods.getValues()}
                prev={prevStep}
                isLoading={isLoading}
                onChange={async (data) => {
                    const [first, ...rest] = data.adjunto;
                    const payload: any = {
                        tipo: requestTypeMap[data.tipo],
                        fecha: data.fecha,
                        archivo: first,
                        descripcion: data.descripcion
                    }
                    if(id){
                        setIsLoading(true);
                        const [error] = await conveniosRequestService.createBasicRequest(id, payload);
                        setIsLoading(false);
                        if(error) return;
                        navigate(-1);
                    }
                }}
            />
        }
    }

    const nextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    const prevStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    return (
        <div className="container d-flex flex-column align-items-center justify-content-center my-4">
            <h1 className="align-self-start">Iniciar una solicitud</h1>
            <div className="col-md-12">
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8">
                        <div className="row mt-2">
                            <Stepper activeStep={activeStep} className="my-5" orientation="horizontal" alternativeLabel connector={<StepConnector />}>
                                {steps.map((step) => (
                                    <Step key={step.label}>
                                        <StepLabel>
                                            {step.label}
                                        </StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            {activeStep === steps.length ? (
                                <Paper square elevation={0} sx={{ p: 3 }}>
                                    <Typography>La solicitud ha sido enviada correctamente</Typography>
                                </Paper>
                            ) : (
                                <div className="row">
                                    {renderStepContent(activeStep)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestNew;