import { useContext } from "react";
import { PageMetaContext } from "../../../context/pagemeta.context";

const PageMeta = () => {
    const meta = useContext(PageMetaContext);
    if (!meta.show) return null;
    return <div>
        {meta.bar && <div className="bar">{meta.bar}</div>}
        <div className="text-center content">
            <h4>{meta.subtitle}</h4>
            <h1>{meta.title}</h1>
            <p>{meta.description}</p>
        </div>
    </div>
}

export default PageMeta;