import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Input, Select } from "../components/common";
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import { usePageMeta } from "../context/pagemeta.context";
import useFilters from "../hooks/useFilters";
import { IBusinessUnit, IProvincia } from "../interfaces";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import businessunitsService from "../services/businessunits.service";
import provinciasService from "../services/provincias.service";

export const UGPage = () => {
    usePageMeta({
        title: "Unidades de Gestión",
        subtitle: "BIENVENIDO A LA PLATAFORMA",
        description: "Estas son las unidades de gestión.",
        show: true
    })
    const { filters, onPageChange, setFilters } = useFilters<IBusinessUnit>({
        pagination: { limit: 10 }
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [UG, setUG] = useState<PaginatedResult<IBusinessUnit>>();

    const [provinciasMap, setProvinciasMap] = useState<Record<string, IProvincia>>({});

    const columns: IColumn<IBusinessUnit>[] = useMemo<IColumn<IBusinessUnit>[]>(() => {
        return [
            {
                displayName: 'Razón Social',
                accesor: "razon_social"
            },
            {
                displayName: 'Provincia',
                accesor: "provincia.nombre",
            },
            {
                displayName: 'Cuit',
                accesor: "cuit",
            },
            {
                displayName: 'Convenios',
                displayFn: ({ row }) => <Link to={`${row.id}/convenios`}><Button variant="primary" size="sm">Acceder</Button></Link>
            }
        ]
    }, []);

    const [state, setState] = useState({
        provincia: '',
        departamento: '',
        gobierno_local: '',
        localidad: '',
        cuit: '',
        razon_social: ''
    })
    const [options, setOptions] = useState<any>({
        provincias: [{ value: '', label: 'Todas' }],
        departamentos: [{ value: '', label: 'Todos' }],
        gobiernoLocales: [{ value: '', label: 'Todos' }],
        localidades: [{ value: '', label: 'Todas' }]
    })

    const mapData = (data: Array<any>): Array<any> => {
        const mapped = data.map(r => ({ value: r.id, label: r.nombre }))
        return mapped;
    }

    const getProvincias = async () => {
        const [error, data] = await provinciasService.getAll();
        if (!error) {
            setProvinciasMap(data.results.reduce((acc, provincia) => ({
                ...acc,
                [provincia.id]: provincia
            }), {}));
            setOptions((state: any) => ({ ...state, provincias: [state.provincias[0], ...mapData(data.results)] }))
        }
    }

    const getDepartamento = async (provinciaId: string) => {
        const [error, data] = await provinciasService.getDepartamentos(provinciaId);
        if (!error) {
            setOptions((state: any) => ({ ...state, departamentos: [state.departamentos[0], ...mapData(data.results)] }))
        }
    }

    const getGobiernosLocales = async (provinciaId: string, departamentoId: string) => {
        const [error, data] = await provinciasService.getGobiernosLocales(provinciaId, departamentoId);
        if (!error) {
            setOptions((state: any) => ({ ...state, gobiernoLocales: [state.gobiernoLocales[0], ...mapData(data.results)] }))
        }
    }

    const getLocalidades = async (provinciaId: string, departamentoId: string, gobiernoLocalId: string) => {
        const [error, data] = await provinciasService.getLocalidades(provinciaId, departamentoId, gobiernoLocalId);
        if (!error) {
            setOptions((state: any) => ({ ...state, localidades: [state.localidades[0], ...mapData(data.results)] }))
        }
    }

    const getUnidadesGestion = async () => {
        setIsLoading(true);
        const [error, data] = await businessunitsService.getAllBU(filters);
        setIsLoading(false);
        if (!error) {
            setUG(data);
        }
    }
    useEffect(() => {
        if (!UG || !provinciasMap) return;
        if (UG.meta.count === 0) return;
        if (typeof UG.results[0].provincia === "object") return;
        UG.results.forEach((r: any) => r.provincia = provinciasMap[r.provincia]);
        setUG(() => ({
            meta: UG.meta,
            results: [...UG.results]
        }));
    }, [provinciasMap, UG])

    useEffect(() => {
        getProvincias();
    }, [])

    useEffect(() => {
        getUnidadesGestion();
    }, [filters])

    useEffect(() => {
        setState(state => ({
            ...state,
            departamento: '',
            gobierno_local: '',
            localidad: ''
        }));
        if (!state.provincia) return;
        getDepartamento(state.provincia);
    }, [state.provincia])

    useEffect(() => {
        setState(state => ({
            ...state,
            gobierno_local: '',
            localidad: ''
        }));
        if (!state.departamento) return;
        if (state.provincia && state.departamento)
            getGobiernosLocales(state.provincia, state.departamento);
    }, [state.departamento])

    useEffect(() => {
        setState(state => ({
            ...state,
            localidad: ''
        }));
        if (!state.gobierno_local) return;
        if (state.provincia && state.departamento && state.gobierno_local)
            getLocalidades(state.provincia, state.departamento, state.gobierno_local);
    }, [state.gobierno_local])

    const onChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        setState(state => ({
            ...state,
            [event.target.name]: event.target.value
        }))
    }

    return <div>
        <div className="d-flex justify-content-center align-items-center">
        <div className="col-md-7">
            <h3 className="mt-3">Unidades de Gestión</h3>
            <div className="d-flex flex-column align-items-center justify-content-center w-100">
                <div className="col-md-8 my-3">
                    <div className="row mt-3">
                    </div>
                    <Form onSubmit={() => { }}>
                        <div className="row my-3">
                            <div className="col-md-3">
                                <Select name="provincia" options={options.provincias} label="Provincia" onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <Select name="departamento" key={state.provincia} options={options.departamentos} label="Departamento" disabled={state.provincia === ''} onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <Select name="gobierno_local" key={state.departamento} options={options.gobiernoLocales} disabled={state.departamento === ''} label="Gobierno Local" onChange={onChange} />
                            </div>
                            <div className="col-md-3">
                                <Select name="localidad" key={state.gobierno_local} options={options.localidades} disabled={state.gobierno_local === ''} label="Localidad" onChange={onChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Input name="razon_social" label="Razón social" onChange={onChange} />
                            </div>
                            <div className="col-md-6">
                                <Input name="cuit" label="CUIT" onChange={onChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mt-3">
                                <Button variant="primary" onClick={() => setFilters((filters: any) => ({ ...filters, ...state }))}>Buscar</Button>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        <PaginatedTable data={UG?.results} columns={columns} onPageChange={onPageChange} meta={{
            ...UG?.meta,
            ...filters.pagination,
        }}
        isLoading={isLoading}
        />
        </div>
        </div>
    </div>
}
