import { IRecoverPassword } from "../interfaces/recoverPassword.interface";

export const useRecovery = () => {
    const recovery = (data: IRecoverPassword) => {
        //recoveryService
        console.log(data);
        return true;
    }
  return [
    recovery
  ]
}
