import { useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input, Select, TextArea } from "../components/common";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import Table from "../components/custom/Table/Table";
import { ConveniosRequest, IAttachment } from "../interfaces/conveniosRequest.interface";
import conveniosRequestService from "../services/conveniosRequest.service";

const RequestDetail = () => {
    const { requestId, id } = useParams();
    const [request, setRequest] = useState<ConveniosRequest>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const getConveniosRequest = async (id: string, reqId: string) => {
        setIsLoading(true);
        const [error, data] = await conveniosRequestService.getRequestById(id, reqId);
        setIsLoading(false);
        if (error) return;
        setRequest(data.results[0]);
    }

    useEffect(() => {
        if (requestId && id) getConveniosRequest(id, requestId);
    }, [requestId, id])

    const columns: IColumn<IAttachment>[] = useMemo<IColumn<IAttachment>[]>(() => {
        return [
            {
                displayName: "Documento",
                accesor: "archivo",
                displayFn: ({ value }) => {
                    if(!value) return null;
                    const paths = value.split("/");
                    const name = paths[paths.length - 1];
                    return <span>{name}</span>
                }
            },
            {
                displayName: "Adjunto",
                accesor: "archivo",
                displayFn: ({ value }) => <a target="_blank" href={value} rel="noreferrer"><Button variant="primary" size="sm"><i className="fa fa-download me-2"></i>Descargar</Button></a>
            }
        ]
    }, []);

    return (
        <div className="container d-flex flex-column align-items-center justify-content-center my-4">
           <h1 className="align-self-start">Detalles de la solicitud</h1>
            <div className="col-md-8">
                <div className="row align-items-center justify-content-center">
                        <div className="row">
                            {request && <Form onSubmit={() => { }}>
                                <Select name="tipo" options={[request]} label="Tipo" disabled getValue={(opt: any) => opt.tipo} getLabel={(opt: any) => opt.tipo} />
                                <Input name="fecha" label="Fecha" defaultValue={`${request.fecha}`} disabled />
                                <TextArea name="descripcion" label="Descripción" defaultValue={request.descripcion} disabled />
                                <Input name="estado" label="Estado" defaultValue={request.estado} disabled />
                            </Form>}
                        </div>
                </div>
                {requestId && <div className="row">
                    <div className="row">
                        <div className="col-md-12 my-4">
                            <h3>Documentación adjunta</h3>
                        </div>
                    </div>
                    <div className="row">
                        <Table data={request?.adjunto} columns={columns} isLoading={isLoading} />
                    </div>
                    <div className="row align-items-center justify-content-center my-4">
                        <div className="col-md-5">
                            <Button variant="link" size="lg" onClick={() => navigate(-1)} className="border border-secondary"><i className="fa fa-backward"></i> Volver a Solicitudes</Button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default RequestDetail;