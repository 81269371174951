import * as yup from "yup";

const schema = yup.object({
    "cantidad_de_titulares": yup.number().min(0, "Este valor no puede ser negativo").required("Ingresa la cantidad de titulares")
    .typeError("Ingresa la cantidad de titulares"),
    "direccion": yup.string().required("Ingresa la direccion"),
    "latitud": yup.number().required("Ingresa la latitud").typeError("Ingresa la latitud"),
    "longitud": yup.number().required("Ingresa la longitud").typeError("Ingresa la longitud"),
    "fecha_de_inicio": yup.date().required("Ingresa una fecha").typeError("Ingresa una fecha"),
    "avance": yup.number(),
    "descripcion": yup.string().required("Descripcion es requerida"),
    "estado": yup.string().required("Ingresa el estado"),
    "rubro": yup.number().required("Ingresa el rubro"),
    "subrubro": yup.number().required("Ingresa el subrubro"),
    "tipo_de_actividad": yup.number().required("Ingresa el tipo de actividad"),
    "provincia": yup.number().required("Ingresa la provincia"),
    "departamento": yup.number().required("Ingresa el departamento"),
    "gobierno_local": yup.number().min(1).required("Ingresa el gobierno local"),
    "localidad": yup.number().required("Ingresa la localidad"),
}).required();

export default schema;