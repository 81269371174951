import { DisplayFn, IColumn } from "./interfaces/Column";
import './styles.scss';

interface Props<T> {
    data?: T[];
    columns: IColumn<T>[];
    isLoading?: boolean;
}

const Table = <T extends unknown>({ data, columns, isLoading }: Props<T>) => {
    const defaultDisplayFn: DisplayFn<T> = ({ value }) => value;

    const getValue = (row: any, accessor?: string) => {
        if (!accessor) return null;
        const [first, ...rest] = accessor.split(".");
        let value = row[first];
        for (let key of rest) {
            value = value[key];
            if (!value) break;
        }
        return value;
    }

    const renderValue = (row: T, col: IColumn<T>) => {
        const singleValue = {
            value: getValue(row, col.accesor as string),
            row,
        }
        if (!col.displayFn) return defaultDisplayFn(singleValue);
        return col.displayFn(singleValue);
    }

    return <div className="table-wrapper">
        <table className="table-responsive card-list-table">
            <thead>
                <tr>
                    {columns.map((column, i) => <th key={i}>
                        {column.displayName}
                    </th>)}
                </tr>
            </thead>
            <tbody>
                {isLoading && <tr><td className="text-center p-4" colSpan={columns.length}>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Cargando...</span>
                    </div>
                </td></tr>}
                {!isLoading && ((data && data.length > 0) ? data.map((row, i) => <tr key={i}>
                    {columns.map((col, j) => <td key={j}>
                        {renderValue(row, col)}
                    </td>)}
                </tr>)
                    : <tr><td className="text-center p-4" colSpan={columns.length}>No hay datos</td></tr>
                )}
            </tbody>
        </table>
    </div>
}

export default Table;