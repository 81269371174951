import * as yup from "yup";

const schema = yup.object({
    nombre: yup.string().required().required("Nombre es requerido"),
    apellido: yup.string().required().required("Apellido es requerido"),
    tipo: yup.number().required().required("Tipo es requerido").typeError("Tipo es requerido"),
    cuil: yup.number().required().required("Cuil es requerido").typeError("Cuil es requerido"),
    fecha_de_inicio: yup.date().required("Fecha de inicio requerida").typeError("Fecha de inicio requerida"),
    fecha_de_fin: yup.string().nullable().optional(),
    telefono: yup.string(),
    email: yup.string().email()
}).required();

export default schema;