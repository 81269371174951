import { mergeClasses } from "../../../utils/merge.classnames";

interface Props {
    size?: "base" | "sm" | "lg";
    className?: string;
    as?: string;
    children: any;
    [x: string]: any;
}

const mapBaseSize = {
    sm: "btn-sm px-3 text-xs",
    base: "px-4 text-base",
    lg: "btn-lg px-5 text-xl"
};

const ButtonBase = ({ size = "base", className, as = "button", children, ...restProps }: Props) => {
    const Element: any = as;
    return (
      <Element
        {...restProps}
        className={mergeClasses(
          "btn",
          mapBaseSize[size],
          className
        )}
      >{children}</Element>
    );
}
  
export default ButtonBase;