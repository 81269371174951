import { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Button } from "../components/common"
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable"
import { IColumn } from "../components/custom/Table/interfaces/Column"
import useFilters from "../hooks/useFilters"
import { Documentation } from "../interfaces/documentation.interface"
import { PaginatedResult } from "../interfaces/paginated.result.interface"
import documentationService from "../services/documentation.service"

export const DocumentationPage = () => {

    const { filters, onPageChange } = useFilters<Documentation>({
        pagination: { limit: 5 }
    });

    const [documentation, setDocumentation] = useState<PaginatedResult<Documentation>>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();
    const {id} = useParams();

    const columns: IColumn<Documentation>[] = useMemo<IColumn<Documentation>[]>(() => {
        return [
            {
                displayName: "Tipo",
                accesor: "tipo"
            },
            {
                displayName: "Fecha",
                accesor: "fecha"
            },
            {
                displayName: "Estado",
                accesor: "estado"
            },
            {
                displayName: "Adjuntos",
                accesor: "adjunto",
                displayFn: ({ value }) => <a target="_blank" href={value} rel="noopener noreferrer"><Button variant="primary" size="sm"><i className="fa fa-download me-2"></i>Descargar</Button></a>
            }
        ]
    }, []);

    const getDocumentation = async (id: string) => {
        setIsLoading(true);
        const [error, data] = await documentationService.getDocumentation(id);
        setIsLoading(false);
        if(error) return;
        setDocumentation(data);
    }

    useEffect(() => {
        if(id){
            getDocumentation(id);
        }
    }, [id])

    return (
        <div className="container">
            <div className="row mt-3">
                <h1>Documentación</h1>
            </div>
            <div className="col-md-12 ms-4">
                <div className="row">
                    <div className="col-md-8">
                        <h3 className="mt-2">Documentación actual</h3>
                    </div>
                    <div className="col-md-4">
                        <Button variant="success" size="lg" onClick={() => navigate("alta")}>Cargar documentación</Button>
                    </div>
                </div>
                <div className="row">
                    <PaginatedTable data={documentation?.results} columns={columns} onPageChange={onPageChange} meta={{
                        ...documentation?.meta,
                        ...filters.pagination
                    }}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    )
}