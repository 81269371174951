import { Button } from "../../../common";
import { requestTypeMap } from "../../../../constants/requestTypeMap";
import { Grid } from "@mui/material";

interface Props {
    type: number;
    values: any;
    next: () => void;
    prev: () => void;
    onChange: (value: any) => void;
}

const Step2Manager = ({ type, values, next, prev, onChange }: Props) => {

    return <div className="d-flex flex-column align-items-center justify-content-center">
        <h3>{requestTypeMap[type]}</h3>
        <div className="row">
            <h4>Manager Step</h4>
        </div>
        <Grid container xs={12} mt={3} justifyContent="center" gap={3}>
            <Button type="button" variant="link" className="border border-secondary" size="lg" onClick={prev}>Anterior</Button>
            <Button form="step2" type="submit" variant="success" size="lg" onClick={next}>Siguiente</Button>
        </Grid>
    </div>
}

export default Step2Manager;