import axios from "axios";
import Cookies from "js-cookie";
import { User } from "../interfaces/user.interface";

interface JWTToken {
    exp: number;
    iat: number;
    user_id: string;
}

interface AuthResponse {
    access_token: string;
    refresh_token: string;
    user: User;
}
/**
 * Authorization and token refresh logic
 */
interface AuthService {
    /**
     * Clears refresh token and access token
     */
    logout: () => void;
    /**
     * @summary Tries to login and save access token
     * @param username user identifier
     * @param password user password
     */
    login: (username: string, password: string) => Promise<JWTToken | undefined>;
    /**
     * @summary Obtains access token to be used in auth header
     * @returns access token or null if not logged in
     */
    getToken: () => string | null;
    refreshAuthToken(): Promise<string>;
    decodeToken: () => JWTToken | undefined;
}

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASEURL,
});

const decodeToken = (): JWTToken | undefined => {
    try {
        const token = getToken();
        if (!token) return;
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    } catch (err) {
        console.log(err);
        logout();
        return;
    }
}

const logout = () => {
    Cookies.remove("refresh_token");
    window.localStorage.removeItem("access_token");
}

const login = async (username: string, password: string) => {
    const authResponse: AuthResponse = await axiosInstance.post("/user/sesion/login/", {
        cuit: username,
        password
    }).then(({ data }) => data);
    Cookies.set("refresh_token", authResponse.refresh_token, { expires: 30 });
    window.localStorage.setItem("access_token", authResponse.access_token);
    const data = decodeToken();
    return data;
}

const refreshAuthToken = async () => {
    const refreshToken = Cookies.get("refresh_token");
    if(!refreshToken) {
        throw new Error("Invalid token");
    }
    const authResponse: any = await axiosInstance.post("/api/token/refresh/", {
        refresh: refreshToken
    }).then(({ data }) => data)
    Cookies.set("refresh_token", authResponse.refresh, {
        expires: 30
    });
    window.localStorage.setItem("access_token", authResponse.access);
    return authResponse.access;
}

const getToken = () => {
    const accessToken = window.localStorage.getItem("access_token");
    return accessToken;
}

const authService: AuthService = {
    logout,
    login,
    getToken,
    refreshAuthToken,
    decodeToken,
}

export default authService;