import { useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/common"
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable"
import { IColumn } from "../components/custom/Table/interfaces/Column"
import useFilters from "../hooks/useFilters";
import { Manager, ManagerType } from "../interfaces/manager.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import ManagersService from "../services/managers.service";
import managerTypeService from "../services/managerType.service";

export const ManagersPage = () => {

    const { filters, onPageChange } = useFilters<Manager>({
        pagination: { limit: 8 }
    });
    const [managers, setManagers] = useState<PaginatedResult<Manager>>();
    const [managerMap, setManagerMap] = useState<Record<number, ManagerType>>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const navigate = useNavigate();
    const {id} = useParams();

    const columns: IColumn<Manager>[] = useMemo<IColumn<Manager>[]>(() => {
        return [
            {
                displayName: 'Nombre',
                accesor: "nombre"
            },
            {
                displayName: 'Apellido',
                accesor: "apellido"
            },
            {
                displayName: 'Tipo',
                accesor: "tipo",
                displayFn: ({value}) => managerMap?.[value].tipo
            },
            {
                displayName: 'Inicio',
                accesor: "inicio",
                displayFn: ({value}) => value ? value : "-"
            },
            {
                displayName: 'Fin',
                accesor: "fin",
                displayFn: ({value}) => value ? value : "-"
            },
            {
                displayName: "Datos",
                displayFn: ({ row }) => <Link to={`${row.id}/`}><Button variant="primary" size="sm">Ver más</Button></Link>
            }
        ]
    }, [managerMap]);

    const getManagerTypes = async (id: string) => {
        const [error, data] = await managerTypeService.getAll();
        if(error) return;
        setManagerMap(data.results.reduce((acc, data) => ({
            ...acc,
            [data.id]: data
        }), {}));
    }

    const getManagers = async (id: string) => {
        setIsLoading(true);
        const [error, data] = await ManagersService.getResponsables(id, filters);
        setIsLoading(false);
        if (!error) setManagers(data);
    }

    useEffect(() => {
        if(id){
            getManagers(id);
            getManagerTypes(id);
        }
    }, [filters])

    return (
        <div className="row align-items-center justify-content-center">
            <div className="col-md-10">
                <h1 className="my-3 ms-5">Responsables</h1>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row align-items-center justify-content-center mb-4">
                        <h3 className="col-md-6">Grilla de responsables</h3>
                        <Button variant="success" size="lg" className="col-md-3" onClick={() => navigate("alta")}>Cargar responsables</Button>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-9">
                            <PaginatedTable data={managers?.results} columns={columns} onPageChange={onPageChange} meta={{
                                ...managers?.meta,
                                ...filters.pagination,
                            }}
                                isLoading={isLoading} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}