import { IActivity } from "../interfaces/activity.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import { Novedades } from "../pages/ActivityDetail";
import AbstractService, { Filter } from "./abstract.service";

class ActivitiesService extends AbstractService<IActivity> {
    constructor(){
        super("/actividad")
    }

    getActivities(uid: string, filters?: Filter<IActivity>): Promise<[Error, PaginatedResult<IActivity>]> {
        return this.getAll(filters, `/convenio/${uid}/`) as any;
    }

    getNovedades(uid: string, filters?: Filter<Novedades>): Promise<[Error, PaginatedResult<Novedades>]>{
        return this.getAll(filters, `${uid}/novedades`) as any;
    }
}

export default new ActivitiesService();