import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button } from "../components/common";
import AmountPerCategoryForm from "../components/form/AmountPerCategoryForm";
import { Category } from "../interfaces";
import amountPerCategoryService from "../services/amoutPerCategory.service";

const CategoryDetail = () => {

    const { categoryId } = useParams();
    const [category, setCategory] = useState<Category>();

    const navigate = useNavigate();

    const getAmountsById = async (id: string) => {
        const [error, data] = await amountPerCategoryService.getOne(id);
        if (error) return;
        setCategory((data as any).results[0]);
    }

    useEffect(() => {
        if (categoryId) getAmountsById(categoryId);
    }, [categoryId])

    return (
        <div className="col-12 col-md-10 col-lg-8 col-xl-5 m-auto">
            <div className="row w-75 my-3">
                <h1 className="col-md-12">Detalles del monto</h1>
            </div>

            {category && <AmountPerCategoryForm disabled={true} defaultValues={category} />}
            <div className="col-md-12">
                <Alert type="primary"><i className="fa fa-info-circle me-1"></i> <h6 className="text-dark font-weight-bold d-inline-block">Sólo se puede modificar el monto por rubro si una solicitud de dicha sección ha sido aprobada.</h6></Alert>
            </div>
            <div className="d-flex mt-4 w-100 justify-content-center align-items-center border-secondary">
                <Button variant="link" size="md" className="border-secondary" onClick={() => navigate(-1)}>
                    <i className="fa fa-backward"></i> Volver a Monto por Rubro
                </Button>
            </div>
        </div>
    )
}

export default CategoryDetail;