import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import useCities from "../../hooks/useCity";
import useDepartment from "../../hooks/useDepartment";
import useFormProvider from "../../hooks/useFormProvider";
import useLocalGobernment from "../../hooks/useLocalGobernment";
import useProvinces from "../../hooks/useProvinces";
import { IActivity } from "../../interfaces/activity.interface";
import { IActivityTradeSubtype } from "../../interfaces/activity.trade.subtype.interface";
import { IActivityTradeType } from "../../interfaces/activity.trade.type.interface";
import { IActivityType } from "../../interfaces/activity.type.interface";
import schema from "../../schemas/activities.schema";
import activitiesTypeService from "../../services/activities.type.service";
import { Button, Input, Select, TextArea } from "../common";

interface Props {
    onSubmit?: (data: any) => void;
    defaultValues?: Partial<IActivity>;
    disabled?: boolean;
}
const ActivitiesForm = ({ onSubmit, defaultValues, disabled }: Props) => {
    const [rubros, setRubros] = useState<IActivityTradeType[]>();
    const [subRubros, setSubRubros] = useState<IActivityTradeSubtype[]>();
    const [tipos, setTipos] = useState<IActivityType[]>();
    const stateOptions = [{
        label: "Presentados",
        value: "Presentados",
    },
    {
        label: "Para subsanar",
        value: "Para subsanar"
    },
    {
        label: "Aceptados",
        value: "Aceptados"
    },
    {
        label: "Finalizados",
        value: "Finalizados"
    }
    ]

    const provinces = useProvinces();
    const { departments, getDepartaments } = useDepartment();
    const { localGobernments, getLocalGobertments } = useLocalGobernment();
    const { cities, getCities } = useCities();
    const { Form, methods } = useFormProvider({ resolver: yupResolver(schema) });
    const provinciaId = methods.watch("provincia");
    const departamentoId = methods.watch("departamento");
    const gobiernoLocalId = methods.watch("gobierno_local");
    useEffect(() => {
        methods.reset({
            estado: "Presentados",
            avance: 0,
            ...defaultValues
        })
        if(defaultValues?.subrubro) {
            getTipo(defaultValues.subrubro);
        }
    }, [])

    const getRubros = async () => {
        const [error, data] = await activitiesTypeService.getRubros();
        if (error) return;
        setRubros([{ nombre: "Seleccione", id: 0 }, ...data.results]);
        await getSubRubros(defaultValues?.rubro || data.results[0].id)
    }

    useEffect(() => {
        methods.resetField("departamento")
        methods.resetField("gobierno_local")
        methods.resetField("localidad")
        if (provinciaId) {
            getDepartaments(provinciaId);
        }
    }, [provinciaId])

    useEffect(() => {
        if (provinciaId && departamentoId) {
            methods.resetField("gobierno_local")
            methods.resetField("localidad")
            getLocalGobertments(provinciaId, departamentoId);
        }
    }, [provinciaId, departamentoId])

    useEffect(() => {
        if (provinciaId && departamentoId && gobiernoLocalId) {
            methods.resetField("localidad")
            getCities(provinciaId, departamentoId, gobiernoLocalId);
        }
    }, [provinciaId, departamentoId, gobiernoLocalId])

    const getSubRubros = async (id: number) => {
        const [error, data] = await activitiesTypeService.getSubRubros(id);
        if (error) return;
        setSubRubros([{ nombre: "Seleccione", id: 0 }, ...data.results]);
    }
    const getTipo = async (subrubroId: number | string) => {
        const [error, data] = await activitiesTypeService.getTipos(subrubroId);
        if (error) return;
        setTipos([{ nombre: "Seleccione", id: 0 }, ...data.results]);
    }

    useEffect(() => {
        getRubros();
    }, []);

    return <Form onSubmit={onSubmit} onSubmitError={(e: any) => console.log(e)}>
        <div className="row mb-2">
            <Select name="rubro"
                disabled={disabled}
                label="Rubro"
                options={rubros}
                getValue={(opt: any) => opt.id}
                getLabel={(opt: any) => opt.nombre}
            />
        </div>
        <div className="row mb-2">
            <Select name="subrubro"
                disabled={disabled}
                label="Subrubro"
                options={subRubros}
                getValue={(opt: any) => opt.id}
                getLabel={(opt: any) => opt.nombre}
                onChange={(opt) => getTipo(opt.currentTarget.value)}
            />
        </div>
        <div className="row mb-2">
            <Select name="tipo_de_actividad"
                disabled={disabled}
                label="Tipos"
                options={tipos}
                getValue={(opt: any) => opt.id}
                getLabel={(opt: any) => opt.nombre}
            />
        </div>
        <div className="row mb-2">
            <div className="col-6">
                <Select name="provincia"
                    disabled={disabled}
                    label="Provincia"
                    options={provinces}
                    getValue={(opt: any) => opt.id}
                    getLabel={(opt: any) => opt.nombre}
                />
            </div>
            <div className="col-6">
                <Select name="departamento"
                    disabled={disabled || !provinciaId}
                    label="Departamento"
                    options={departments}
                    getValue={(opt: any) => opt.id}
                    getLabel={(opt: any) => opt.nombre}
                    key={provinciaId}
                />
            </div>
        </div>
        <div className="row mb-2">

            <div className="col-6">
                <Select name="gobierno_local"
                    disabled={disabled || !departamentoId}
                    label="Gobierno Local"
                    options={localGobernments}
                    getValue={(opt: any) => opt.id}
                    getLabel={(opt: any) => opt.nombre}
                    key={departamentoId}
                />
            </div>
            <div className="col-6">
                <Select name="localidad"
                    disabled={disabled || !gobiernoLocalId}
                    label="Localidad"
                    options={cities}
                    getValue={(opt: any) => opt.id}
                    getLabel={(opt: any) => opt.nombre}
                    key={gobiernoLocalId}
                />
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-6">
                <Input name="longitud" type="number" defaultValue="0" disabled={disabled} label="Longitud" />
            </div>
            <div className="col-6">
                <Input name="latitud" type="number" defaultValue="0" disabled={disabled} label="Latitud" />
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-6">
                <Input name="direccion" type="text" disabled={disabled} label="Dirección" />
            </div>
            <div className="col-6">
                <Input name="cantidad_de_titulares" type="number" defaultValue="0" disabled={disabled} label="Cantidad de titulares" />
            </div>
        </div>
        <div className="row mb-2">
            <div className="col-6">
                <Input name="fecha_de_inicio" type="date" disabled={disabled} label="Fecha de inicio" />
            </div>
            <div className="col-6">
                <Select name="estado" options={stateOptions} disabled={disabled} label="Estado" />
            </div>
        </div>
        <div className="row mb-2">
            <TextArea name="descripcion" type="textarea" label="Descripción" disabled={disabled} />
        </div>
        {!disabled && <div className="row mb-2">
            <div className="col-6">
                <Button variant="primary">Guardar</Button>
            </div>
        </div>}
    </Form>
}
export default ActivitiesForm;