import { useEffect, useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { usePageMeta } from "../../context/pagemeta.context";
import NavIcon from "../common/NavIcon";
import './styles.scss';

const ConvenioLayout = () => {

  const location = useLocation();
  const isBasePath = useMemo(()=>
  location.pathname.replaceAll(/[\/]*$/g,"").split("/").length <= 5, [location]);

  const {hide} = usePageMeta({show: false});

  useEffect(() => {
    if(!isBasePath){
      hide();
    }
  }, [isBasePath]);

  return (
    <div>
        {/* NAV ICONS  */}
        <section>
            <div className="col-md-12 bg-lightgray active">
              <ul className={`nav nav-icons py-4 ${!isBasePath ? 'nav-icons-selected' : ''}`}>
                <li>
                  {/* icono-arg-comisiones-cofefup-equipo */}
                  <NavIcon bgcolor="#6ea100" icon="icono-arg-comisiones-cofefup-equipo" to={"actividades"} label="Actividades"/>
                </li>
                <li>
                  {/* icono-arg-comunidad */}
                  <NavIcon bgcolor="#2e7d33" icon="icono-arg-comunidad" to={"responsables"} label="Responsables"/>
                </li>
                <li>
                  {/* icono-arg-monedas */}
                  <NavIcon bgcolor="#008388" icon="icono-arg-monedas" to={"montoporrubro"} label="Monto por Rubro"/>
                </li>
                <li>
                  {/* icono-arg-arma */}
                  <NavIcon bgcolor="#6a1b99" icon="icono-arg-arma-imp" to={"intervenciones"} label="Invervenciones"/>
                </li>
                <li>
                  {/* icono-arg-documento */}
                  <NavIcon bgcolor="#039be5" icon="icono-arg-documento" to={"documentacion"} label="Documentación"/>
                </li>
                <li>
                  {/* icono-arg-archivo-consulta */}
                  <NavIcon bgcolor="#0072bb" icon="icono-arg-archivo-consulta" to={"solicitudes"} label="Solicitudes"/>
                </li>
              </ul>
          </div>
        </section>
        <Outlet />
    </div>
  )
}

export default ConvenioLayout;