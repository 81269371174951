import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/common";
import ManagersForm from "../components/form/ManagersForm";
import { Manager } from "../interfaces/manager.interface";
import managersService from "../services/managers.service";

export interface ManagerType {
    id?: number;
    tipo: string;
}

const ManagerDetail = () => {

    const [manager, setManager] = useState<Manager>();
    const { managerId } = useParams();
    const navigate = useNavigate();

    const getManagerById = async (id: string) => {
        const [error, data] = await managersService.getOne(id);
        if (error) return;
        setManager((data as any).results[0]);
    }


    useEffect(() => {
        if (managerId) getManagerById(managerId);
    }, [managerId])

    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-10 p-4">
                <h1>Datos Personales</h1>
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8 mb-4">
                        {manager && <>
                            <ManagersForm defaultValues={manager} disabled={true} />
                            <div className="d-flex mt-4 w-100 justify-content-center align-items-center border-secondary">
                                <Button variant="link" size="md" className="border-secondary" onClick={() => navigate(-1)}>
                                    <i className="fa fa-backward"></i> Volver a Grilla de Responsables
                                </Button>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagerDetail;