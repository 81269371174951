import { yupResolver } from "@hookform/resolvers/yup";
import Table from "../../../custom/Table/Table";
import { createRef, useMemo, useState } from "react";
import useFormProvider from "../../../../hooks/useFormProvider";
import { Documentation } from "../../../../interfaces/documentation.interface";
import schema from "../../../../schemas/genericStep2.schema";
import { Button } from "../../../common";
import { IColumn } from "../../Table/interfaces/Column";
import { requestTypeMap } from "../../../../constants/requestTypeMap";
import { Grid } from "@mui/material";

interface Props {
    type: number;
    values: any;
    next: () => void;
    prev: () => void;
    onChange: (value: any) => void;
}

const Step2Generic = ({ type, values, next, prev, onChange }: Props) => {

    const ref = createRef<HTMLInputElement>();
    const { Form, methods } = useFormProvider({
        resolver: yupResolver(schema), mode: "all", defaultValues: {
            ...values,
        }
    });

    const columns: IColumn<File>[] = useMemo<IColumn<File>[]>(() => {
        return [
            {
                displayName: "Documento",
                accesor: "name"
            },
            {
                displayName: "Adjunto",
                displayFn: ({ row }) => <a target="_blank" href={URL.createObjectURL(row)}><Button variant="primary" size="sm"><i className="fa fa-download me-2"></i>Descargar</Button></a>
            }
        ]
    }, []);

    const onSubmitInternal = (formData: any) => {
        onChange(formData);
        next();
    }

    return <div className="d-flex flex-column align-items-center justify-content-center">
        <h3>{requestTypeMap[type]}</h3>
        <div className="row">
            <h4>Documentación Adjunta</h4>
            <div className="row">
                <div className="col-md-8">
                    <p className="m-0">Adjunte la documentación necesaria. Extensiones permitidas archivos <span>pdf, xlsx o jpg</span><i className="fa fa-asterisk text-danger ms-1"></i></p>
                </div>
                <div className="col-md-4">
                    <Form id="step2" onSubmit={(value: any) => {onSubmitInternal(value)}}>
                        <input multiple type="file" className="d-none" ref={ref} accept="image/jpeg,application/pdf" name="adjunto" onChange={(e) => {
                            if(e.target.files){
                                methods.setValue("adjunto", Array.from(e.target.files), {shouldValidate: true});
                            }
                            console.log(methods.getValues())
                        }} />
                    </Form>
                    <Button type="button" variant="primary" size="sm" onClick={() => ref.current?.click()}><i className="fa fa-paperclip me-2"></i>Adjuntar</Button>
                </div>
            </div>
            <Table data={methods.getValues("adjunto")} columns={columns} />
        </div>
        <Grid container xs={12} mt={3} justifyContent="center" gap={3}>
            <Button type="button" variant="link" className="border border-secondary" size="lg" onClick={prev}>Anterior</Button>
            <Button form="step2" type="submit" variant="success" size="lg" disabled={!methods.formState.isValid}>Siguiente</Button>
        </Grid>
    </div>
}

export default Step2Generic;