import { Alert, Button, Form, Input, TextArea } from "../components/common";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import { useEffect, useMemo, useState } from "react";
import useFilters from "../hooks/useFilters";
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable";
import { Link, useParams } from "react-router-dom";
import activitiesService from "./../services/activities.service";
import { Filter } from "../services/abstract.service";
import { IActivity } from "../interfaces/activity.interface";
import ActivitiesForm from "../components/form/ActivitiesForm";
export interface Novedades {
    id?: number,
    tipo: string;
    fecha: Date;
    usuario: string;
}
const ActivityDetail = () => {
    const { filters, onPageChange } = useFilters<Novedades>({
        pagination: { limit: 5 }
    });
    const [novedades, setNovedades] = useState<PaginatedResult<Novedades>>();
    const [activity, setActivity] = useState<IActivity>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { activityId } = useParams();
    const columns: IColumn<Novedades>[] = useMemo<IColumn<Novedades>[]>(() => {
        return [
            {
                displayName: 'Tipo',
                accesor: "tipo"
            },
            {
                displayName: "Fecha",
                accesor: "fecha"
            },
            {
                displayName: 'Usuario',
                accesor: "usuario"
            },
            {
                displayName: 'Detalles',
                displayFn: ({ row }) => <Link to={`/novedades/${row.id}`}><Button variant="primary" size="sm">Acceder</Button></Link>
            }
        ]
    }, []);
    const getNovedades = async (id: string, filters: Filter<Novedades>) => {
        setIsLoading(true);
        const [error, data] = await activitiesService.getNovedades(id, filters);
        setIsLoading(false);
        if (error) return;
        setNovedades(data);
    }
    const getActivityById = async (id: string) => {
        const [error, data] = await activitiesService.getOne(id);    
        if(error) return;
        const r: any = data;
        setActivity(r.results[0]);            
    }
    useEffect(() => {
        if(activityId) getActivityById(activityId);
    }, [activityId])
    
    useEffect(() => {
        if (activityId) getNovedades(activityId, filters);
    }, [filters])
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <div className="w-75 mt-4 ms-5">
                <h1>Actividad</h1>
                <h3 className="my-4">Datos principales</h3>
            </div>
            <div className="w-50">
            {activity && <ActivitiesForm defaultValues={activity} disabled={true}/> }
                <div className="mt-4">
                    <Alert type="primary">
                        <i className="fa fa-info-circle me-1"></i>Datos anteriores sólo podrán editarse si usted tiene una solicitud de cambio de datos en estado aceptada y no registra avance en la actividad.
                    </Alert>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <div className="w-75 ms-5">
                    <h3>Avance</h3>
                </div>
                <div className="my-2 w-50">
                    <div className="row">
                        <div className="col-md-2">
                            <label className="control-label">Porcentaje:</label>
                        </div>
                        <div className="col-md-10">
                            <label className="control-label">Paso {activity?.avance} de 100%</label>
                            <div className="row justify-content-center align-items-center">
                                <div className="col-md-8">
                                    <ProgressBar now={activity?.avance} label={`${activity?.avance}%`} visuallyHidden />
                                </div>
                                <div className="col-md-4">
                                    <Button variant='primary' size="base">Editar</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
                <div className="row w-75">
                    <div className="d-flex align-items-center">
                    <h3 className="col-sm-9 col-xs-12 m-2">Novedades o visitas</h3>
                    <button type="button" className="btn btn-success col-xs-12 col-sm-3 col-xs-12 py-2" >Cargar novedades</button>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center w-100">
                    <div className="col-md-12 mb-4 mt-4 w-50">
                        <PaginatedTable data={novedades?.results} columns={columns} meta={{
                            ...novedades?.meta,
                            ...filters.pagination
                        }}
                            onPageChange={onPageChange}
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ActivityDetail;