import { HttpClientOptions } from "./client/http.client";
import httpClient from "./client/http.client";
import { AxiosInstance } from "axios";
import { PaginatedResult, PaginationMeta } from "../interfaces/paginated.result.interface";
import paramsSerializer from "../utils/params.serializer";
import { defaultPaginationFilters } from "../constants/default.pagination";


interface IPaginationFilter {
  pagination?: {
    offset?: number;
    page?: number;
    limit?: number;
  }
}


type Primitives = string | boolean | string[] | number | undefined | null ;
/**
 * Generic type for filtering, paginating and sorting
 */
export type Filter<T> =  IPaginationFilter & {
  [Prop in keyof T | (string & {})]?: any;
};
/* End magic  */

/**
 * @summary Base class for all services
 *
 */
type Response<T> = PaginationMeta & {
  results: T[];
}

const mapMeta = <T>(data: Response<T>): PaginatedResult<T> => {
  return {
    results: data?.results || [],
    meta: {
      count: data?.count || 0,
      limit: data?.limit || 10,
      page: data?.page || 1
    }
  }
}

class AbstractService<T> {
  protected client: AxiosInstance;
  /**
   *
   * @param path api route
   * @param options http client options
   */
  constructor(path: string, options?: HttpClientOptions) {
    this.client = httpClient(path, options);
  }

  create = (entity: T): Promise<[Error, T]> => this.client.post("/", entity);

  getAll = (
    filters: Filter<T> = defaultPaginationFilters,
    url = "/"
  ): Promise<[Error, PaginatedResult<T>]> => {
    const { pagination, ...rest } = { ...filters };
    return this.client.get<any, [Error, Response<T>]>(url, {
      params: {
        ...pagination,
        ...rest
      },
      paramsSerializer,
    }).then(([error, res]) => [error, mapMeta(res)]);
  }

  getOne = (id: number | string, filters?: Filter<T>): Promise<[Error, T]> =>
    this.client.get(`/${id}/`, {
      params: {
        ...filters,
      },
      paramsSerializer,
    });

  update = (id: number | string, entity: T): Promise<[Error, T]> =>
    this.client.patch(`/${id}/`, entity);

  remove = (id: number | string, params?: any): Promise<[Error, void]> =>
    this.client.delete(`/${id}/`, { params: { ...params } });
}

export default AbstractService;
