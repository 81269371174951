import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/common";
import PaginatedTable from "../components/custom/PaginatedTable/PaginatedTable";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import useFilters from "../hooks/useFilters";
import { IActivity } from "../interfaces/activity.interface";
import { IActivityType } from "../interfaces/activity.type.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import activitiesService from "../services/activities.service";
import activitiesTypeService from "../services/activities.type.service";

export const ActivitiesPage = () => {

    const [activityTypeMap, setActivityTypeMap] = useState<Record<number, IActivityType>>();

    const { filters, onPageChange } = useFilters<IActivity>({
        pagination: { limit: 5 }
    });

    const navigate = useNavigate();
    const {id} = useParams();

    const [activities, setActivities] = useState<PaginatedResult<IActivity>>();

    const [isLoading, setIsLoading] = useState<boolean>();

    const columns: IColumn<IActivity>[] = useMemo<IColumn<IActivity>[]>(() => {
        return [
            {
                displayName: 'Tipo de Actividad',
                accesor: "tipo_de_actividad",
                displayFn: ({value}) => activityTypeMap?.[value].nombre
            },
            {
                displayName: "Descripcion",
                accesor: "descripcion"
            },
            {
                displayName: 'Avance',
                accesor: "avance"
            },
            {
                displayName: 'Estado',
                accesor: "estado",
            },
            {
                displayName: 'Datos',
                displayFn: ({ row }) => <Link to={`${row.id}`}><Button variant="primary" size="sm">Acceder</Button></Link>
            }
        ]
    }, [activityTypeMap]);

    const getActivities = async (id: string) => {
        setIsLoading(true);
        const [error, data] = await activitiesService.getActivities(id, filters);
        setIsLoading(false);
        if (!error) setActivities(data);
    }

    const getActivitiesTypes = async () => {
        const [error, data] = await activitiesTypeService.getTipos();
        if(error) return;
        setActivityTypeMap(data.results.reduce((acc, data) => ({
            ...acc,
            [data.id]: data
        }), {}));
    }

    useEffect(() => {
        if(id){
            getActivities(id);
            getActivitiesTypes();
        }
    }, [id, filters])

    return (
        <div className="container">
            <h1 className="mt-4">Actividades</h1>
            <div className="row w-100">
                <div className="d-flex align-items-center">
                    <h4 className="col-sm-9 col-xs-12 m-2">Grilla de actividades</h4>
                    <Button variant="success" size="lg" className="col-md-3" onClick={() => navigate("alta")}>Cargar Actividad</Button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mb-4 mt-3">
                    <PaginatedTable data={activities?.results} columns={columns} onPageChange={onPageChange} meta={{
                        ...activities?.meta,
                        ...filters.pagination
                    }}
                        isLoading={isLoading}
                    />

                </div>
            </div>
        </div>
    )
}
