import { useState } from "react"
import { IGobiernoLocal } from "../interfaces"
import provinciasService from "../services/provincias.service";

const useLocalGobernment = () => {
    const [data, setData] = useState<Partial<IGobiernoLocal>[]>();
    const getLocalGobertments = async (provinceId: number | string, departmentId: number | string) => {
        const [error, data] = await provinciasService.getGobiernosLocales(provinceId, departmentId);
        if(error) return;
        setData([{id: 0, nombre: "Seleccione"}, ...data.results]);
    }

    return {getLocalGobertments, localGobernments: data};
}
export default useLocalGobernment;