import { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import './styles.scss';
import mappedRoutes from '../../../routes/routes';

const BreadCrums = () => {
    const location = useLocation();

    const paths = useMemo(() => {
        const routes = location.pathname.split("/");
        // remove base url
        routes.shift();
        //const last = routes.pop();
        let i = 0;
        const paths = [];
        for (let route of routes) {
            const mapped = mappedRoutes[route];
            if (!mapped) continue;
            const href = routes.slice(0, i + 1).join("/");
            paths.push ({ name: mapped.displayName, href });
            i++;
        }
        paths.unshift({ name: "Inicio", href: "/" });
        return paths;
    }, [location]);

    return <div className="w-100 breadcrum text-capitalize">
        <ul>
            {paths.map(({ name, href }) => <li key={name}>
                <Link to={"/" + href}>{name}</Link>
            </li>)}
        </ul>
    </div>
}

export default BreadCrums;