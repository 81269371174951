import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, Select } from "../components/common";
import { IColumn } from "../components/custom/Table/interfaces/Column";
import Table from "../components/custom/Table/Table";
import useFormProvider from "../hooks/useFormProvider";
import authService from "../services/auth.service";
import documentationService from "../services/documentation.service";

const LoadDocumentation = () => {

    const {id} = useParams();
    const navigate = useNavigate();
    const [adjunto, setAdjunto] = useState<File[]>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const documentationOptions = React.useMemo(() => [
        {value: "Facturas de Compra", label: "Facturas de Compra"},
        {value: "Formulario de Gastos", label: "Formulario de Gastos"}
    ], [])

    const ref = createRef<HTMLInputElement>()
    const {Form, methods} = useFormProvider({
        defaultValues: {
            tipo: "Facturas de Compra",
            adjunto: null,
            fecha: new Intl.DateTimeFormat('sv').format(new Date()),
            estado: ""
        }
    });

    const columns: IColumn<File>[] = useMemo<IColumn<File>[]>(() => {
        return [
            {
                displayName: 'Documento',
                accesor: "name",
            },
            {
                displayName: "Adjunto",
                displayFn: ({row}) => <a target="_blank" href={URL.createObjectURL(row)}><Button variant="primary" size="sm"><i  className="fa fa-download me-2"></i>Descargar</Button></a>
            },
        ]
    }, []);

    const onSubmit = async (payload: any) => {
        const token = authService.decodeToken();
        if(!id) return;
        setIsLoading(true); 
        const [error] = await documentationService.saveDocumentation(id, {...payload, usuario: token?.user_id});
        setIsLoading(false);
        if(error) return;
        navigate(-1);
    } 

    return (
        <div className="row flex-column align-items-center justify-content-center w-100 h-100">
            <div className="row w-75 my-3">
                <h1>Cargar una Documentación</h1>
            </div>
            <div className="col-md-6 col-xxl-4">
                <h3>Datos</h3>
                <Form onSubmit={onSubmit} id="form-adjuntos">
                    <Select name="tipo" options={documentationOptions} label="Tipo" />
                    <div className="col-4 mt-2">
                        <Input name="fecha" type="date" label="Fecha" />
                    </div>
                </Form>
                <h3 className="mt-5">Documentación Adjunta</h3>
                <div className="row py-3 ps-3">
                    <div className="col-md-8">
                        <p className="m-0">Extensiones permitidas <span className="text-primary">PDF o JPG.</span><i  className="fa fa-asterisk text-danger ms-2" ></i></p>
                    </div>
                    <div className="col-md-4 text-end">
                        <input type="file" className="d-none" ref={ref} accept="image/jpeg,application/pdf" name="adjunto" onChange={(e) => {
                            if(e.target.files){
                                setAdjunto(Array.from(e.target.files));
                            }
                            methods.setValue("adjunto", e.target.files?.[0]);
                        }}/>
                        <Button variant="primary" size="sm" onClick={() => ref.current?.click()}><i  className="fa fa-paperclip me-2"></i>Adjuntar</Button>    
                    </div>
                </div>
                <Table data={adjunto} columns={columns} />
                <div className="row align-items-center justify-content-center my-3 gap-2">
                    <div className="col-md-3">
                                <Button variant="link" size="lg" className="border-secondary" onClick={() => navigate(-1)}>
                                    Anterior
                                </Button>                        
                    </div>
                    <div className="col-md-6">
                        <Button variant="success" size="lg" type="submit" disabled={!adjunto || isLoading} form="form-adjuntos">Cargar documentación</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadDocumentation;