import { ChangeEvent, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form"
import './styles.scss';

interface IOption {
    label?: string;
    value?: string;
}

type Option<T> = IOption & {
    [Prop in keyof T]: any;
}

interface Props<T> {
    label?: string;
    name: string;
    id?: string;
    required?: boolean;
    options?: IOption[] | Option<T>[];
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
    getValue?: (option: IOption | Option<T>) => string;
    getLabel?: (option: IOption | Option<T>) => string;
}

const Select = <T extends unknown>({ name, onChange, options, disabled = false, label, getValue, getLabel }: Props<T>) => {
    const { formState: { errors = {} }, control } = useFormContext();
    const hasErrors = !!errors[name]?.message;

    return (
        <div className="form-group item-group">
            <label className="control-label" htmlFor={name}>{label}</label>
            <Controller
            control={control}
            name={name}
            render={({field: {...props}}) => 
            <select className="form-select"
                {...props}
                onChange={(e) => {
                    props.onChange(e);
                    onChange?.(e);
                }}
                disabled={disabled}
                >
                {options?.map((opt: any) => <option value={getValue?.(opt)} key={getValue?.(opt)}>{getLabel?.(opt)}</option>)}
            </select>} />
            {hasErrors && <p className="text-danger mb-0" role="alert">{errors?.[name]?.message?.toString()}</p>}
        </div>
    )
}

Select.defaultProps = {
    getValue: (option: IOption) => option.value,
    getLabel: (option: IOption) => option.label
}

export default Select;