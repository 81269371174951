import { useMemo } from "react";

const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
};

interface Props {
    totalCount: number;
    pageSize: number;
    currentPage: number;
}

export const usePagination = ({
    totalCount,
    pageSize,
    currentPage
}: Props) => {
    const pageData = useMemo(() => {
        const totalPages = Math.ceil(totalCount / pageSize);
        const totalPageNumbers = 3;
        const next = Math.min(totalPages, currentPage + 1);
        const prev = Math.max(1, currentPage - 1);

       if (totalPageNumbers >= totalPages) {
            return {
                totalPages,
                next,
                prev,
                pages: range(1, totalPages)
            }
        }

        const leftIndex = Math.max(currentPage, 1);
        const rightIndex = Math.min(
            currentPage,
            totalPages
        );

        const showLeft = leftIndex > 2;
        const showRight = rightIndex < totalPages - 2;

        const firstPageIndex = 1;

        if (!showLeft && showRight) {
            let leftItemCount = 3;
            let leftRange = range(1, leftItemCount);

            return {
                totalPages,
                next,
                prev,
                pages: [...leftRange]
            }
        }

        if (showLeft && !showRight) {
            let rightItemCount = 3;
            let rightRange = range(
                totalPages - rightItemCount + 1,
                totalPages
            );
            return {
                totalPages,
                next,
                prev,
                pages: [...rightRange]
            }
        }


        let middleRange = range(leftIndex - 1, rightIndex + 1);
        return {
            totalPages,
            next,
            prev,
            pages: [...middleRange]
        }
    }, [totalCount, pageSize, currentPage]);

    return pageData;
};