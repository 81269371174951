import { useState } from "react";
import { Filter } from "../services/abstract.service";

const useFilters = <T extends unknown>(initialValues?: Filter<T>)=> {
    
    const [filters, setFilters] = useState<Filter<T>>({
        ...initialValues,
        pagination: {
            ...initialValues?.pagination,
            page: 1
        }        
    });

    const onPageChange = (page: number) => {
        setFilters((state) => ({...state, pagination: {...state.pagination, page, offset: (page - 1) * (state.pagination?.limit || 5)}}))
    }

    const onFiltersChange = (name: string, value: any) => {
        setFilters((state: any) => ({...state, [name]: value}))
    }

    return {
        filters,
        setFilters,
        onPageChange,
        onFiltersChange
    }
    
}

export default useFilters;