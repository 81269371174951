import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivitiesForm from "../components/form/ActivitiesForm";
import activitiesService from "../services/activities.service";

export interface Novedades {
    id?: number,
    tipo: string;
    fecha: Date;
    usuario: string;
}

const ActivityNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    return (
        <div className="d-flex flex-column align-items-center justify-content-center w-100 h-100">
            <div className="w-75 mt-4 ms-5">
                <h1>Actividad</h1>
                <h3 className="my-4">Datos principales</h3>
            </div>
            <div className="w-50">
                <ActivitiesForm disabled={isLoading} onSubmit={async (data) => {
                    setIsLoading(true);
                    const [error] = await activitiesService.create({
                        ...data,
                        convenio: id,
                        avance: 0,
                        fecha_de_inicio: data.fecha_de_inicio.toJSON().split("T")[0]
                    })
                    setIsLoading(false);
                    if (error) return;
                    navigate(-1);
                }} />
            </div>
        </div>
    )
}

export default ActivityNew;