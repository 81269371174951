import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input } from "../components/common";
import { usePageMeta } from "../context/pagemeta.context";
import { IBusinessUnit, IConvenio } from '../interfaces';
import businessunitsService from '../services/businessunits.service';
import conveniosService from '../services/conveniosService';
import { formatNumber } from '../utils/text.utils';

const FormConvenioPage = () => {
  const { setMeta } = usePageMeta({
    show: true,
    subtitle: "CONVENIO",
    description: "",
  })

  const { ugId, id } = useParams();
  const [convenio, setConvenio] = useState<IConvenio>();
  const getConvenioById = async (id: string) => {
    const [error, data] = await conveniosService.getOne(id);
    if (error) return;
    const value = (data as any).results[0];
    setConvenio(value);
    setMeta((meta: any) => ({ ...meta, description: value.numero_de_expediente }));
  }

  const getBusinessUnitById = async (id: string) => {
    const [error, data] = await businessunitsService.getOne(id);
    if (error) return;
    const UG: IBusinessUnit = (data as any).results?.[0];
    setMeta((meta: any) => ({ ...meta, bar: UG?.razon_social }));
  }

  useEffect(() => {
    if(ugId) getBusinessUnitById(ugId)
  }, [ugId])

  useEffect(() => {
    if (id) {
      getConvenioById(id)
    }
  }, [id])

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100 mb-4 mt-4">
      <div className="col-md-6">
        <div className="row">

          <h4>Datos Principales</h4>
        </div>
        <Form options={{ defaultValues: { ...convenio, monto: "$" + formatNumber((convenio?.monto || 0).toString()) } }} key={convenio?.numero_de_expediente}>
          <div className="row">
            <Input name="numero_de_expediente" type="text" disabled={true} label="Expediente" />
          </div>
          <div className="row">
            <Input name="resolucion" type="text" disabled={true} label="Resolución" />
          </div>
          <div className="row">
            <Input name="monto" type="text" disabled={true} label="Monto" />
          </div>
          <div className="row">
            <Input name="fecha_resolucion" type="text" disabled={true} label="Fecha de resolución" />
          </div>
          <div className="row">
            <div className="col-6">
              <Input name="fecha_de_inicio" type="text" disabled={true} label="Fecha de inicio" />
            </div>
            <div className="col-6">
              <Input name="fecha_de_vencimiento" type="text" disabled={true} label="Fecha de vencimiento" />
            </div>
          </div>
          <div className="row">
            <Input name="resolucion_adenda" type="text" disabled={true} label="Resolución adendada" />
          </div>
          <div className="row">
            <Input name="fecha_resolucion_adenda" type="text" disabled={true} label="Fecha de resolución de adenda" />
          </div>
          <div className="row">
            <Input name="convenio_anterior" type="text" disabled={true} label="Convenio anterior" />
          </div>
          <div className="row">
            <Input name="estado" type="text" disabled={true} label="Estado" />
          </div>
        </Form>
      </div>
    </div>
  )
}

export default FormConvenioPage;