import { useEffect } from "react";
import userService from "../services/user.service";
import { Navigate } from "react-router-dom"
import useAuth from "../hooks/useAuth";

export const HomePage = () => {

    const { isAuth } = useAuth();
    
    const getUser = async () => {
        const [error, data] = await userService.getAll();
        if(error) return;
        console.log(data);        
    }

    useEffect(() => {
        getUser();
    }, [])

    return isAuth ? <Navigate to="/unidadesgestion"/> : null;
}