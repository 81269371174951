import { ConveniosRequest } from "../interfaces/conveniosRequest.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class ConveniosRequestService extends AbstractService<ConveniosRequest> {
    constructor(){
        super("/solicitudes")
    }

    getAllRequestById(uid: string, filters?: Filter<ConveniosRequest>): Promise<[Error, PaginatedResult<ConveniosRequest>]>{
        return this.getAll(filters, `/convenio/${uid}/`);
    }

    getRequestById(uid: string, reqId: string, filters?: Filter<ConveniosRequest>): Promise<[Error, PaginatedResult<ConveniosRequest>]>{
        return this.getAll(filters, `/convenio/${uid}/solicitud/${reqId}/`);
    }
    createBasicRequest(uid: string, req: ConveniosRequest): Promise<[Error,ConveniosRequest]> {
        return this.client.postForm(`/convenio/${uid}/addSolicitudBasica/`, req)
    }
}

export default new ConveniosRequestService();