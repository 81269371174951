import { Routes, Route, Navigate } from "react-router-dom";
import ConvenioLayout from "./components/layout/ConvenioLayout";
import DefaultLayout from "./components/layout/DefaultLayout";
import UGLayout from "./components/layout/UGLayout";
import ProtectedRoute from "./components/route/ProtectedRoute";
import useAuth from "./hooks/useAuth";
import { ActivitiesPage, HomePage, NotFoundPage } from "./pages";
import ActivityDetail from "./pages/ActivityDetail";
import ActivityNew from "./pages/ActivityNew";
import CategoryDetail from "./pages/AmountPerCategoryDetail";
import CategoryNew from "./pages/AmountPerCategoryNew";
import { AmountPerCategoryPage } from "./pages/AmountPerCategoryPage";
import { DocumentationPage } from "./pages/DocumentationPage";
import FormConvenioPage from "./pages/FormConvenioPage";
import { InterventionPage } from "./pages/InterventionPage";
import LoadDocumentation from "./pages/LoadingDocumentation"
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import ManagerDetail from "./pages/ManagerDetail";
import ManagerNew from "./pages/ManagerNew";
import { ManagersPage } from "./pages/ManagersPage";
import { RecoverPasswordPage } from "./pages/RecoverPasswordPage";
import { RecoveryPasswordSuccesfullPage } from "./pages/RecoveryPasswordSuccesfullPage";
import RequestDetail from "./pages/RequestDetail";
import RequestNew from "./pages/RequestNew";
import { ConveniosRequestPage } from "./pages/requestPage";
import UGDetail from "./pages/UGDetail";
import { UGPage } from './pages/UGPage';

export default function App() {

  const { isReady } = useAuth();
  if (!isReady) return <p>Cargando...</p>

  return (
    <Routes>
      <Route element={<DefaultLayout />}>
        <Route index element={<HomePage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="logout" element={<LogoutPage />} />
        <Route path="recuperar-contrasena" element={<RecoverPasswordPage />} />
        <Route path="recuperar-contrasena-exitosa" element={<RecoveryPasswordSuccesfullPage />} />
      </Route>
      <Route element={<ProtectedRoute />}>
        <Route element={<DefaultLayout />}>
          <Route path="unidadesgestion" element={<UGLayout />}>
            <Route index element={<UGPage />} />
            <Route path=":ugId">
              <Route index element={<Navigate to='convenios' />} />
              <Route path="convenios">
                <Route index element={<UGDetail />} />
                <Route path=":id" element={<ConvenioLayout />}>
                  <Route index element={<FormConvenioPage />} />
                  <Route path="responsables">
                    <Route index element={<ManagersPage />} />
                    <Route path="alta" element={<ManagerNew />} />
                    <Route path=":managerId" element={<ManagerDetail />} />
                  </Route>
                  <Route path="actividades">
                    <Route index element={<ActivitiesPage />} />
                    <Route path="alta" element={<ActivityNew />} />
                    <Route path=":activityId" element={<ActivityDetail />} />
                  </Route>
                  <Route path="montoporrubro">
                    <Route index element={<AmountPerCategoryPage />} />
                    <Route path="alta" element={<CategoryNew />} />
                    <Route path=":categoryId" element={<CategoryDetail />} />
                  </Route>
                  <Route path="intervenciones">
                    <Route index element={<InterventionPage />} />
                  </Route>
                  <Route path="documentacion">
                    <Route index element={<DocumentationPage />} />
                    <Route path="alta" element={<LoadDocumentation />} />
                  </Route>
                  <Route path="solicitudes">
                    <Route index element={<ConveniosRequestPage />}/>
                    <Route path="alta" element={<RequestNew />} />
                    <Route path=":requestId" element={<RequestDetail />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route element={<DefaultLayout />}>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}