interface Props {
    children: React.ReactNode;
    label?: string;
    type: "primary" | "secondary" | "danger" | "success" | "warning" | "info";
}

const Alert = ({ children, label, type }: Props) => {
    return (
        <div className={`alert alert-${type} border border-3 border-${type} mt-2`}>
            {label && <strong>{label}<br /></strong>}
            {children}
        </div>
    )

}
export default Alert;