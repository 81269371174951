import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form"

interface Props {
    label?: string;
    name: string;
    type?: string;
    required? :boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    hideErrors?: boolean;
    disabled?: boolean;
    defaultValue?: string;
}

const Input = ({label, name, type, required, onChange, hideErrors, disabled, defaultValue}: Props) => {
    const { register, formState: {errors = {}} } = useFormContext();
    const hasErrors = !!errors[name]?.message;
    const props = register(name);
    
    const onChangeInternal = (e: ChangeEvent<HTMLInputElement>) =>{
        props.onChange(e);
        onChange?.(e);
    }
    
    return (
        <div className={`form-group item-group ${hasErrors ? 'has-error' : ''}`}>
            <label className="control-label" htmlFor={name}>{label}</label>
            <input
                {...props}
                defaultValue={defaultValue}
                onChange={onChangeInternal}
                type={type}
                className="form-control"
                id={name}
                name={name}
                required={required} 
                aria-required={required}
                disabled={disabled}
                />
            {!hideErrors && <p className="text-danger mb-0">{errors?.[name]?.message?.toString()}</p>}
        </div>
    )
}

Input.defaultProps = {
    type: "text",
    required: false,
    label: ""
}

export default Input;