import axios, { AxiosError, AxiosInstance } from 'axios'
import authService from '../auth.service';

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token: string | null = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}
/**
 * @param secured true if the request should send authorization header
 * @param baseURL base url of the api
 */
export interface HttpClientOptions {
    secured?: boolean
    baseURL?: string
}

const parseError = (error: AxiosError | Error) => {
    if (axios.isAxiosError(error)) {
        return error?.response?.data || error.message
    }
    return error.message
}
/**
 * @summary Creates an instance of HttpClient to be used in every request
 * @param path api route
 * @param options 
 * @returns {AxiosInstance}
 */
const httpClient = (path: string, options?: HttpClientOptions): AxiosInstance => {
    const baseURL = options?.baseURL || process.env.REACT_APP_API_BASEURL
    const instance = axios.create({ baseURL })
    instance.defaults.baseURL += path

    instance.interceptors.request.use(async (config) => {
        const cfg = { ...config, headers: { ...config.headers } };
        const token = authService.getToken();
        if(token){
            cfg.headers.Authorization = `Bearer ${token}`;
        }
        return cfg;
    }, (error) => {
        return Promise.reject(error);
    });

    // intercept response and redirect if needed
    instance.interceptors.response.use(
        (response) => {
            if (response.data instanceof Blob) {
                return Promise.resolve([null, response])
            }
            return Promise.resolve([null, response.data])
        },
        (error) => {
            const originalRequest = error.config;
            const errorMessage = parseError(error);
            if (error?.response?.status === 401 && !originalRequest._retry) {

                if (isRefreshing) {
                    return new Promise(function (resolve, reject) {
                        failedQueue.push({ resolve, reject })
                    }).then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return Promise.resolve(axios(originalRequest));
                    }).catch(err => {
                        return Promise.reject(err);
                    }).then((res: any) => [null, res.data]).catch(err => [parseError(err), null])
                }

                originalRequest._retry = true;
                isRefreshing = true;

                return new Promise(function (resolve, reject) {
                    authService.refreshAuthToken().then(token => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        processQueue(null, token);
                        resolve(axios(originalRequest));
                    })
                        .catch((err) => {
                            processQueue(err, null);
                            reject(err);
                            window.location.replace("/logout")
                        })
                        .finally(() => { isRefreshing = false })
                }).then((res: any) => [null, res.data]).catch(err => [parseError(err), null]);
            }
            return Promise.resolve([errorMessage, null]);
        }
    )
    return instance
}

export default httpClient
