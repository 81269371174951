import { IActivityTradeSubtype } from "../interfaces/activity.trade.subtype.interface";
import { IActivityTradeType } from "../interfaces/activity.trade.type.interface";
import { IActivityType } from "../interfaces/activity.type.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class ActivitiesTradeTypesService extends AbstractService<IActivityTradeType>{
    constructor(){
        super("")
    }
    getRubros(filters?: Filter<IActivityTradeType>) : Promise<[Error, PaginatedResult<IActivityTradeType>]> {
        return this.getAll(filters, `rubro_de_tipo_de_actividad/`) as any;
    }
    getSubRubros(tradeId: number, filters?: Filter<IActivityTradeType>) : Promise<[Error, PaginatedResult<IActivityTradeSubtype>]>{
        return this.getAll({...filters, rubro: tradeId}, `subrubro_de_tipo_de_actividad/`) as any;
    }
    getTipos(subRubroId?: number | string, filters?: Filter<IActivityType>) : Promise<[Error, PaginatedResult<IActivityType>]> {
        return this.getAll({...filters, subRubro: subRubroId}, `tipo_de_actividad/`) as any;
    }
}

export default new ActivitiesTradeTypesService();