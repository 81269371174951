import { FormProvider, useForm, UseFormProps } from "react-hook-form"

interface Props {
    children: React.ReactNode;
    onSubmit?: (data: any) => void;
    onSubmitError?: (data: any) => void;
    options?: UseFormProps;
    onChange?: () => {}
}
const Form = ({ children, onSubmit, options, onSubmitError, onChange }: Props) => {
    const methods = useForm(options);
    return <FormProvider {...methods}>
        <form onSubmit={onSubmit && methods.handleSubmit(onSubmit, onSubmitError)}>
            {children}
        </form>
    </FormProvider>
}

export default Form;