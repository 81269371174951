import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ManagersForm from "../components/form/ManagersForm";
import managersService from "../services/managers.service";


const ManagerNew = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-10 p-4">
                <h1>Datos Personales</h1>
                <div className="row align-items-center justify-content-center">
                    <div className="col-md-8 mb-4">
                        <ManagersForm disabled={isLoading} onSubmit={async (data) => {
                            setIsLoading(true);
                            const [error] = await managersService.create({
                                ...data,
                                convenio: id
                            });
                            setIsLoading(false);
                            if(error) return;
                            navigate(-1);
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ManagerNew;