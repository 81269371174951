import { Link } from "react-router-dom";
import { usePagination } from "../../../hooks/usePagination";
import { PaginationMeta } from "../../../interfaces/paginated.result.interface";
import './styles.scss';

interface Props {
    meta?: PaginationMeta;
    onPageChange?: (page: number) => void;
}
const paginationDefault: PaginationMeta = { page: 1, limit: 10, count: 0 };


const Pagination = ({ meta = paginationDefault, onPageChange }: Props) => {
    const {pages, prev, next, totalPages} = usePagination({
        totalCount: meta.count || 0,
        currentPage: meta.page || 1,
        pageSize: meta.limit || 10
    })

    const renderButtons = () => {
      
        return pages?.map(pageNumber => <li key={pageNumber} className={`paginate_button ${meta.page === pageNumber ? 'active' : ''}`}>
                <Link to="#" onClick={() => onPageChange?.(pageNumber)}>{pageNumber}</Link>
        </li>)
    }

    return <ul className="pagination">
        <li className={`paginate_button previous ${(meta.page || 1) <= 1 ? 'disabled' : ''}`}>
            <Link to="#" onClick={() => onPageChange?.(prev)}>&lt;</Link>
        </li>
        {renderButtons()}
        <li className={`paginate_button next ${(meta.page || 1) + 1 > totalPages ? 'disabled' : ''}`}>
            <Link to="#" onClick={() => onPageChange?.(next)}>&gt;</Link>
        </li>
    </ul>
}

export default Pagination;