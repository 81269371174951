import { IDepartamento, IGobiernoLocal, ILocalidad, IProvincia } from "../interfaces";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class ProvinciasService extends AbstractService<IProvincia> {
    constructor(){
        super("/provincias")
    }
    getDepartamentos(id: string | number, filters?: Filter<IDepartamento>): Promise<[Error, PaginatedResult<IDepartamento>]>{
        return this.getAll(filters, `/${id}/departamentos/`);
    }
    getGobiernosLocales(provinciaId: string | number, departamentoId: string | number, filters?: Filter<IGobiernoLocal>): Promise<[Error, PaginatedResult<IGobiernoLocal>]>{
        return this.getAll(filters, `/${provinciaId}/departamentos/${departamentoId}/gobiernos_locales/`) as any;
    }
    getLocalidades(provinciaId: string | number, departamentoId: string | number, gobiernoLocalId: string | number, filters?: Filter<ILocalidad>): Promise<[Error, PaginatedResult<ILocalidad>]>{
        return this.getAll(filters, `/${provinciaId}/departamentos/${departamentoId}/gobiernos_locales/${gobiernoLocalId}/localidades/`);
    }
}

export default new ProvinciasService();