import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate,  } from "react-router-dom";
import { Alert, Form, Input } from "../components/common";
import { useRecovery } from "../hooks/useRecovery";
import { IRecoverPassword } from "../interfaces/recoverPassword.interface";
import schema from "../schemas/recoverPassword.schema";



export const RecoverPasswordPage = () => {

    const [{email}, setError] = useState({
        email: { message:'' }
    });
    const [recovery] = useRecovery();
    const onSubmitError = (errors:any) => setError(errors);
    const navigate = useNavigate();
    const onSubmit = (data: IRecoverPassword) => {
        recovery(data);
        navigate('/recuperar-contrasena-exitosa',{replace: true, state: {email: data.email}});
        
    }

  return (
    <div className="d-flex justify-content-center align-items-center w-100 h-100">
            <div className="col-lg-4 col-md-10 mt-5 mx-3">
                <div className="row">
                    <h4>Recuperá tu contraseña</h4>
                    <p>Con tu correo electrónico un asesor interno se contactará para que puedas obtener una nueva contraseña.</p>
                    <div className="col-md-10">
                        {email?.message &&
                            <Alert type='danger' label='Se han producido los siguientes errores: '>
                                {email?.message && <div>{email?.message}</div>}
                            </Alert>
                        }

                        <Form
                            onSubmit={onSubmit}
                            onSubmitError={onSubmitError}
                            options={{ resolver: yupResolver(schema) }}>

                            <Input name="email" type="email" label="Ingresa tu correo electrónico" hideErrors={true} />

                            <button type="submit" className="btn btn-primary btn-block mt-2">Enviar</button>
                        </Form>
                        <hr />
                        <Link to={"/"} replace>Volver al inicio</Link>
                    </div>

                </div>
            </div>
        </div>
  )
}
