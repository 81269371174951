import { Manager } from "../interfaces/manager.interface";
import { PaginatedResult } from "../interfaces/paginated.result.interface";
import AbstractService, { Filter } from "./abstract.service";

class ManagersService extends AbstractService<Manager> {
    constructor() {
        super("/responsable")
    }
    getResponsables(uid: string, filters?: Filter<Manager>): Promise<[Error, PaginatedResult<Manager>]> {
        return this.getAll(filters, `/convenio/${uid}/`) as any;
    }
}

export default new ManagersService();