import React, { useEffect, useState } from "react";
import { User } from "../interfaces/user.interface";
import authService from "../services/auth.service";
import userService from "../services/user.service";

interface IAuthContext {
    isAuth: boolean;
    isReady: boolean;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    user?: User;
}

export const AuthContext = React.createContext<IAuthContext>({
    isAuth: false,
    isReady: false,
    login: () => Promise.resolve(false),
    logout: () => { }
});

const AuthProvider = ({ children }: any) => {
    const [isAuth, setIsAuth] = useState<boolean>(false);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [user, setUser] = useState<User>();

    const getUser = async () => {
        const [error, data] = await userService.getAll();
        if(error) return;
        setUser(data.results?.[0]);
    }

    useEffect(() => {
        const isLogged = isLoggedIn();
        setIsAuth(isLogged);
        setIsReady(true);
        if(isLogged) getUser();
    }, [])

    const login = async (username: string, password: string) => {
        try {
            await authService.login(username, password);
            setIsAuth(true);
            getUser();
            return true;
        }catch(e){
            return false;
        }
    }

    const logout = () => {
        authService.logout();
        setIsAuth(false);
        setUser(undefined)
    }

    const isLoggedIn = () => !!authService.getToken();

    return <AuthContext.Provider value={{ login, logout, isAuth, isReady, user }}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider;